<style>
    ::ng-deep .p-tooltip {
        max-width: fit-content;
    }

    .sig-up {
        color: #cf2e2e;
    }

    .sig-down {
        color: #0000ff;
    }
        
    .custom-download {
    font-size: 16px; 
    padding: 5px 18px; 
}

.custom-download .pi-download {  
    transform: scale(1.2);  
    margin-right: 8px; 
}

    .custom-icon-size .pi {
    font-size: 1.5em;   
    transform: scale(1.2);   
}
</style>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card" style="padding-left:40px">
            <h1 style='margin: 0px'>Feature View</h1>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="p-d-flex p-jc-center" style="margin-top:50px; padding-left: 75px; padding-right: 75px">
                We computed all omics feature ~ metadata associations across five different omics types and more than 100 metadata variables. 
                Sex, age, BMI, and culture time were included as covariates in all analyses, unless one of these variables was the 
                primary metadata variable. Search either a gene/protein symbol or metadata variable to retrieve relevant results, sorted by p-value. 
            </div>
            <div class="p-d-flex p-jc-center p-ai-center" style="margin-top:75px; margin-bottom:75px">
                <p-autoComplete [(ngModel)]="selectedSearchName" [suggestions]="filteredSearchNames" (completeMethod)="filterQueries($event)" 
                field="label" [inputStyle]="{'width':'700px'}" placeholder="Search a gene or metadata variable ..."
                (keyup.enter)="performSearch(selectedSearchName)" minLength="3"></p-autoComplete>
                <div style="margin-left: 50px">
                    <p-button label="Search" (click)="performSearch(selectedSearchName)" icon="pi pi-search" [loading]="loadingFeature"></p-button>
                </div>
            </div>
            <div *ngIf="searchResults" style="width:90%; margin-left:auto; margin-right:auto; margin-bottom: 50px">
                <div class="p-grid">
                    <div class="p-col-10">
                        <p>Features with positive associations have red gene/protein symbols and features with negative associations have blue gene/protein symbols. 
                            Click a row in the results table to generate a scatter plot of the feature ~ metadata variable.
                        </p>
                    </div>
                    <div class="p-col-2" style="text-align: right">
                        <button pButton type="button" label="Download Results"
                        class="p-button-warning" (click)="downloadFeatureResults()"
                        icon="pi pi-download"></button>
                    </div>
                </div>
                <div style="margin-top:50px">
                    <p-table #dt1 [columns]="searchColumns" [value]="searchResults" rowHover="true"
                    selectionMode="single" (onRowSelect)="plotFeature($event, 'table')"
                    [(selection)]="selectedFeature" [paginator]="true"
                    [rows]="25" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[25, 50, 100]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>
                                Gene/Protein Symbol
                                <p-columnFilter type="text" field="Feature" display="menu"></p-columnFilter>
                            </th>
                            <th>
                                Metadata Variable
                                <p-columnFilter type="text" field="Metadata" display="menu"></p-columnFilter>
                            </th>
                            <th>
                                Omics type
                                <p-columnFilter type="text" field="Omics type" display="menu"></p-columnFilter>
                            </th>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th>
                                Links
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td>
                                <span pTooltip='{{rowData["Description"]}}'
                                    [ngClass]="{'sig-up': (rowData['Coefficient'] > 0), 'sig-down': (rowData['Coefficient'] < 0)}">
                                    {{ rowData["Feature"] }}</span>
                            </td>
                            <td>
                                <span pTooltip='{{rowData["Metadata group"]}}'>
                                    {{ rowData["Metadata"] }}</span>
                            </td>
                            <td>
                                <span>{{ rowData["Omics type"] }}</span>
                            </td>
                            <td *ngFor="let col of columns">
                                {{ rowData[col.field] }}
                            </td>
                            <td>
                                <a href='https://www.ncbi.nlm.nih.gov/gene/?term={{rowData["Gene ID"]}}'
                                    target='_blank'
                                    style="text-decoration: underline;">NCBI</a>&nbsp;&nbsp;&nbsp;
                                <a href='https://t2d.hugeamp.org/gene.html?gene={{rowData["Feature"]}}'
                                    target='_blank'
                                    style="text-decoration: underline;">T2DKP</a>&nbsp;&nbsp;&nbsp;
                                <a role="button" style="text-decoration: underline;"
                                    (click)='plotSingleCell(rowData["Gene ID"], rowData["Feature"], rowData["Description"])'>SC</a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="{{currentFeature}}: {{currentDescription}}" [(visible)]="displaySCFeature"
    [style]="{width: '600px', height: '100%'}" (onHide)="clearFeature()">
    <div class="p-grid p-ai-center p-jc-center vertical-container" *ngIf="loadingImage == 'yes'"
        style="width:100%; height:100%">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div>
        <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="550px">
    </div>
    <ng-template pTemplate="footer">
         <button pButton type="button" label="SVG" (click)="dwlFeature('svg')" 
                class="custom-download"></button>
        <button pButton type="button" label="PNG" (click)="dwlFeature('png')" 
                class="custom-download"></button>
         <button pButton type="button" label="PDF" (click)="dwlFeature('pdf')" 
                class="custom-download"></button>
        <button pButton type="button" icon="pi pi-check" (click)="clearFeature()" label="OK"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="{{currentFeature}}: {{currentDescription}}" [(visible)]="displayFeature"
    [style]="{width: '500px', height: '550px'}" (onHide)="clearFeature()">
    <div class="p-grid p-ai-center p-jc-center vertical-container" *ngIf="loadingImage == 'yes'"
        style="width:100%; height:100%">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <app-boxplot [boxData]="boxData" [primaryType]="primaryType" [selectedOmicsType]="selectedOmicsType" 
                   [currentFeature]="currentFeature" [selectedPrimary]="selectedPrimary" 
                   [metaLabels]="metaLabels" (newItemEvent)="queryDonor($event)"></app-boxplot>
 
    <ng-template pTemplate="footer">
        <button pButton type="button" label="SVG" (click)="dwlFeature('svg')" 
                class="custom-download"></button>
        <button pButton type="button" label="PNG" (click)="dwlFeature('png')" 
                class="custom-download"></button>
         <button pButton type="button" label="PDF" (click)="dwlFeature('pdf')" 
                class="custom-download"></button>
        <button pButton type="button" icon="pi pi-check" (click)="clearFeature()" label="OK"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>