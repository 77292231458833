import {Component} from '@angular/core';

@Component({
    selector: 'app-footer', 
    template: `
        <div class="footer">
            <div class="card clearfix">
                <span class="footer-text-left" style="margin-left: 100px;">Data visualization portal for the HumanIslets project</span>
                <span class="footer-text-right">
                    <span>Xia Lab</span>
                  </span>
            </div>
        </div>
    `
})
export class AppFooterComponent {

}
