import { Component, OnInit, ViewEncapsulation, Input, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import * as d3 from "d3";
import { csvParse } from "d3";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { DomSanitizer } from '@angular/platform-browser';

interface ImgType {
  name: string,
  code: string
}

@Component({
  selector: 'isolation-inf',
  templateUrl: './isolation-inf.component.html',
  styleUrls: ['./isolation-inf.component.css'],
  encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class IsolationInfComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() donorInf: any;
  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  subBaseUrl: Subscription;
  baseUrl: string;

  data: any;
  svg: any;
  donorInfType: string;
  base64Image: any;
  imageToShow: any;
  isletImgs!: ImgType[];
  selectedIsletImg!: ImgType;
  cellComp: boolean = true;

  // img names
  postisoImg: any;
  predistImg: any;

  data1: any;
  data2: any;
  svgBar: any;
  xAxis: any;
  yAxis: any;
  barMargin: any = { top: 20, right: 20, bottom: 20, left: 30 };
  barHeight: number = 250;

  selImage: string = 'postiso';
  imageOptions: any[] = [{ label: 'Post-isolation', value: 'postiso' }, { label: 'Pre-distribution', value: 'predist' }];

  tooltip: any;
  colWidth: number = 250;
  csvUrl: string;

  // donor parameters
  donorID: string;
  selectedVar: string = "coldischemiatime";
  units: string = "Hours";
  histTitle: string = "coldischemiatime";

  constructor(private http: HttpClient, private global: GlobalVarsService, private element: ElementRef, private domSanitizer: DomSanitizer) {
    this.data1 = [
      { group: "Exocrine", value: 20 },
      { group: "Beta", value: 40 },
      { group: "Alpha", value: 20 },
      { group: "Delta", value: 15 },
      { group: "Gamma", value: 5 }
    ];

    this.data2 = [
      { group: "Beta", value: 60 },
      { group: "Alpha", value: 30 },
      { group: "Delta", value: 10 },
    ];

    this.donorInfType = typeof this.donorInf;
  }

  ngOnInit(): void {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => {
      this.baseUrl = data
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-numerical_isolation_info.csv";

    })

  }

  ngAfterViewInit(): void {
    this.colWidth = this.myIdentifier.nativeElement.offsetWidth - 30; // determine plot width based on grid size
  }

  ngOnChanges() {

    if (typeof this.csvUrl !== 'undefined') {
      // add text labels
      this.donorInfType = typeof this.donorInf;
      this.donorID = this.donorInf.record_id
      this.main();

      this.cellComp = true;
      this.barChart(this.colWidth, this.barHeight, "#CellTypeBar");
      this.updateBar('decon', this.colWidth, this.barHeight)

      // get images
      this.http.get(this.baseUrl + "download/humanislets/images-islets-postiso-" + this.donorID + "_postiso_32.png", { responseType: 'blob' }).subscribe(data => {
        this.createImageFromBlob(data, "postiso");
      }, error => {
        this.postisoImg = null;
      });

      this.http.get(this.baseUrl + "download/humanislets/images-islets-predist-" + this.donorID + "_predist_32.png", { responseType: 'blob' }).subscribe(data => {
        this.createImageFromBlob(data, "predist");
      }, error => {
        this.predistImg = null;
      });
    }
  }

  main() {
    // make plots
    this.http.get(this.csvUrl, { responseType: 'text' }).subscribe(data => {
      this.data = csvParse(data, d3.autoType);

      this.histTitle = this.donorInf[this.selectedVar].name;
      this.units = this.donorInf[this.selectedVar].units;

      if (this.selectedVar == "totalieq") {
        this.basicHistogram(this.data, this.selectedVar, this.colWidth, 250, 20, "#VarHistogram", 50, "#43a047", this.donorInf[this.selectedVar].value, this.donorInf[this.selectedVar].perc)
      } else {
        this.basicHistogram(this.data, this.selectedVar, this.colWidth, 250, 20, "#VarHistogram", 40, "#43a047", this.donorInf[this.selectedVar].value, this.donorInf[this.selectedVar].perc)
      }
    });
  }

  basicHistogram(inputData: any, varName: string, width: number, height: number, binPar: number, selectTerm: string,
    marginBottom: number, color: string, vert: number, perc: number) {

    d3.select(selectTerm).select("svg").remove();

    // remove NA - too many messes up histogram function
    inputData = inputData.filter(d => d[varName] != 'NA');

    var margin = { top: 0, right: 10, bottom: marginBottom, left: 10 }
    var compHeight = height - margin.bottom - margin.top;

    var xExtent = d3.extent(d3.map(inputData, d => d[varName]))

    // set the parameters for the histogram
    const bins = d3.bin()
      .thresholds(binPar)
      .value((d) => d[varName])
      (inputData);

    // Create the x-axis
    const x = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([0, width - margin.right - margin.left]);

    // Create the y-axis
    var y = d3.scaleLinear()
      .range([compHeight, 0]);
    y.domain([0, d3.max(bins, function (d) { return d.length; })]);

    // Create SVG
    this.svg = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    this.svg.append("g")
      .attr("transform", "translate(0," + compHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)")
      .call(g => g.select(".domain").remove())

    this.svg.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
      .attr("width", function (d) { return x(d.x1) - x(d.x0); })
      .attr("height", function (d) { return compHeight - y(d.length); })
      .style("fill", color)
      .attr("fill-opacity", "0.6")

    if (!isNaN(vert)) {
      var percText = (perc*100).toString() + "th perc."
      this.svg.append("line")
        .attr("y1", 0)
        .attr("y2", compHeight)
        .attr("x1", x(vert))
        .attr("x2", x(vert))
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none");

      if (x(vert) > (width / 2)) {
        this.svg.append("text")
          .attr("x", x(vert) - 30)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      } else {
        this.svg.append("text")
          .attr("x", x(vert) + 5)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      }
    }

  }

  barChart(outerWidth: number, outerHeight: number, selectTerm: string) {

    d3.select(selectTerm).select("svg").remove();

    var innerWidth = outerWidth - this.barMargin.left - this.barMargin.right;
    var innerHeight = outerHeight - this.barMargin.top - this.barMargin.bottom;


    // Create SVG
    this.svgBar = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", outerWidth)
      .attr("height", outerHeight)
      .attr("viewBox", [0, 0, outerWidth, outerHeight])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + this.barMargin.left + "," + this.barMargin.top + ")");

    this.svgBar.append("text")
      .attr("x", -this.barMargin.left)
      .attr("y", -10)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .text("Proportion (%)");

    this.xAxis = this.svgBar.append("g")
      .attr("transform", "translate(0," + innerHeight + ")")

    this.yAxis = this.svgBar.append("g")

  }

  updateBar(dataName: string, outerWidth: number, outerHeight: number,) {

    if (dataName == 'decon') {
      if (this.donorInf.cellType.exo < 0) { // current estimates have negative values. this will be addressed with new deconvolution method.
        this.data1[0].value = 'NA';
      } else {
        this.data1[0].value = this.donorInf.cellType.exo;
      }

      // some donors return empty arrays
      if (this.donorInf.cellType.exo.length == 0) {
        this.data1[0].value = 'NA';
        this.data1[1].value = 'NA';
        this.data1[2].value = 'NA';
        this.data1[3].value = 'NA';
        this.data1[4].value = 'NA';
        this.cellComp = false;
      } else if (this.donorInf.cellType.beta == 'NA') {
        this.cellComp = false;
      } else {
        this.cellComp = true;
        this.data1[1].value = this.donorInf.cellType.beta;
        this.data1[2].value = this.donorInf.cellType.alpha;
        this.data1[3].value = this.donorInf.cellType.delta;
        this.data1[4].value = this.donorInf.cellType.gamma;
      }

      var data = this.data1;

      if (this.cellComp) {
        var innerWidth = outerWidth - this.barMargin.left - this.barMargin.right;
        var innerHeight = outerHeight - this.barMargin.top - this.barMargin.bottom;

        const x = d3.scaleBand()
          .range([0, innerWidth])
          .padding(0.2)
        x.domain(data.map(function (d) { return d.group; }))

        const y = d3.scaleLinear()
          .range([innerHeight, 0]);
        y.domain([0, d3.max(data, function (d) { return Number(d['value']); })]);

        // Update the X axis
        this.xAxis.call(d3.axisBottom(x))

        // Update the Y axis
        this.yAxis.transition().duration(1000)
          .call(d3.axisLeft(y))

        // Create the u variable
        var u = this.svgBar.selectAll("rect")
          .data(data)

        u
          .enter()
          .append("rect") // Add a new rect for each new elements
          .merge(u) // get the already existing elements as well
          .transition() // and apply changes to all of them
          .duration(1000)
          .attr("x", function (d) { return x(d.group); })
          .attr("y", function (d) { return y(d.value); })
          .attr("width", x.bandwidth())
          .attr("height", function (d) { return innerHeight - y(d.value); })
          .attr("fill", "#cc00ff")
          .attr("fill-opacity", "0.6")

        // remove so it doesn't pile up
        u.exit().remove()
      } else {
        d3.select("#CellTypeBar").select("svg").remove();

        var innerWidth = outerWidth - this.barMargin.left - this.barMargin.right;
        var innerHeight = outerHeight - this.barMargin.top - this.barMargin.bottom;
    
    
        // Create SVG
        this.svgBar = d3.select(this.element.nativeElement)
          .select("#CellTypeBar")
          .append('svg')
          .attr("width", outerWidth)
          .attr("height", outerHeight)
          .attr("viewBox", [0, 0, outerWidth, outerHeight])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
          .style("-webkit-tap-highlight-color", "transparent")
          .append("g")
          .attr("transform",
            "translate(" + this.barMargin.left + "," + this.barMargin.top + ")");
    
        this.svgBar.append("text")
          .attr("x", 20)
          .attr("y", 50)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text("No cell type estimates for this donor.")
          .style("font-size", "12pt")
      }
    }
  }

  activeMenu(event) {
    let node;
    if (event.target.tagName === "SPAN") {
      node = event.target.parentNode.parentNode;
    } else {
      node = event.target.parentNode;
    }
    if (node != "submenu") {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      node.classList.add("active");
    }
  }

  createImageFromBlob(image: Blob, imgNm: string) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.base64Image = reader.result;

      if (imgNm == "postiso") {
        this.postisoImg = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
      } else if (imgNm == "predist") {
        this.predistImg = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
      }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
