<style>
    li {
        margin: 0 0 10px 0;
    }
</style>

<div class="p-grid" style='padding:30px 50px 0px 50px;'>
    <div class="p-col-12">
        <div class="card" style='padding:100px; font-size:14pt; overflow: auto;'>
            <h1>Updates</h1>
            <div style="margin-top:50px">
                <ul>
                    <li>
                        Our paper 
                        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11212983/" target="_blank">has been accepted</a> 
                        at Cell Metabolism! (09/20/2024)
                    </li> 
                    <li>
                        Added the link for HumanIslets.com Webinar(08/26/2024)
                    </li>
                    <li>
                        Added tutorial for a website walkthrough(06/20/2024)
                    </li>                  
                    <li>
                        Added dynamic perifusion data for ~20 more donors (05/19/2024)
                    </li>                      
                    <li>
                        Added an 'About' page (05/12/2024)
                    </li>
                    <li>
                        Updated the 'Terms of Service' (05/12/2024)
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>