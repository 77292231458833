import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { RestAPIService } from '../service/rest-api.service';
import { GlobalVarsService } from '../service/global-vars.service';
import { SelectItemGroup, Message } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { csvParse } from "d3";
import * as d3 from "d3";
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { retry, switchMap,catchError } from 'rxjs/operators';
import { throwError,combineLatest,of } from 'rxjs';

@Component({
    selector: 'app-omics',
    templateUrl: './app.omics.component.html',
})

export class AppOmicsComponent implements OnInit, OnDestroy {
    countries: any[] | undefined;

    selectedCity: any;


    @ViewChild('dt1') table: Table;

    // global variables
    subUsrDir: Subscription;
    subBaseUrl: Subscription;

    baseUrl: string;
    usrDir: string;
    currentMsg: Message[];

    // component variables
    loadingImage: string = "no";
    base64Image: any;
    imageToShow: any;

    pvalThresh: number = 0.05;
    fdrFunc: number = 0.05;
    collapsePathways: boolean = false;
    fdr: boolean = true;
    donorOpt: string = "all";
    funcOpt: string = "ora";
    uploadIds: string;

    varClasses: SelectItem[];
    class1: string = "Type2";
    class2: string = "None";

    omicsType: SelectItem[];
    selectedOmicsType: string = "proc_prot";

    variableGroups: SelectItem[];
    selectedGroup: string = "donor";

    varsDonor: SelectItem[];
    varsOrgan: SelectItem[];
    varsIsolation: SelectItem[];
    varsCellPro: SelectItem[];
    varsCellCulture: SelectItem[];
    varsGSIS: SelectItem[];
    varsSeahorse: SelectItem[];
    varsPerifusion: SelectItem[];
    varsGRS: SelectItem[];
    varsEphysDonor: SelectItem[];
    varsEphysCell: SelectItem[];

    cellType: SelectItem[];
    selectedCell: string = "alpha";

    glucConc: SelectItem[];
    selectedGluc: string = "1";

    covariates: SelectItemGroup[];
    selectedCovariates: any[];
    selectedPrimary: string = "diagnosis";
    primaryType: string = "disc";

    IDType: SelectItem[];
    selectedIDType: string = "redcap";

    libraryOpts: SelectItem[];
    selectedLibrary: string = "kegg";

    rankingOpts: SelectItem[];
    selectedRanking: string = "coef";
    ridgeXLab: string;

    displayDonor: boolean = false;
    displayGsea: boolean = false;
    displayFeature: boolean = false;
    displaySCFeature: boolean = false;
    displayPathway: boolean = false;
    displayZero:boolean = false;
    displaySCDonor: boolean = false;
    displayInterFeature: boolean = false;

    listExists: boolean = false;

    varData: any;
    groupData: any;
    omicsData: any;
    deaResults: any;
    funcResults: any;
    ridgeData: any;

    deaColumns: any[];
    deaColNames: any[];
    funcColumns: any[];
    funcColNames: any[];
    covGroups: any[] = ['Donor Characteristics', 'Organ Characteristics and Processing', 'Isolation Outcomes', 'Cell Culture Outcomes', 'Static Insulin Secretion', 'Islet Oxygen Consumption (Seahorse Assay)', 'Dynamic Insulin Responses to Macronutrients', 'Single-cell Function'];

    virtualFeatures: any;
    loading: boolean = false;
    loadingDEA: boolean = false;
    loadingFunc: boolean = false;
    totalRecords: number;
    loadRows: number;
    featureHeader: string;
    featureES: string;
    sigNum: string;
    sigUp: string;
    sigDown: string;
    sigPathNum: string;
    nDonors: string;
    nCells: string;
    currentFeature: string;
    currentPathway: string;
    currentDescription: string;
    selectedFeature: any[];
    searchFeature: string;
    trackFeature: string;
    index: number = 0;
    pageURL: string;

    boxData: any[];
    metaLabels: any[];
    figNm: string;
    
    constructor(private apiService: RestAPIService, private domSanitizer: DomSanitizer,
        private global: GlobalVarsService, private http: HttpClient, private router: Router) {

        this.subBaseUrl = this.global.baseUrl.subscribe(data => this.baseUrl = data)
 
        this.http.get(this.baseUrl + "download/humanislets/display_interface-proc_variable_summary.csv", { responseType: 'text' }).subscribe(data => {
            this.varData = csvParse(data);
            this.makeVarsOpts();
            this.makeCovariatesOpts();
        });

        this.http.get(this.baseUrl + "download/humanislets/display_interface-disc_groups.csv", { responseType: 'text' }).subscribe(data => {
            this.groupData = csvParse(data);
            this.makeComparisonOpts(this.selectedPrimary);
        });

        this.http.get(this.baseUrl + "download/humanislets/display_interface-omics_types.csv", { responseType: 'text' }).subscribe(data => {
            this.omicsData = csvParse(data);
            this.makeOmicsOpts();
        });

     


        this.IDType = [
            { label: 'Donor ID', value: 'redcap' },
            { label: 'RRID', value: 'rrid' }
        ];

        this.cellType = [
            { label: 'Alpha Cells', value: 'Alpha' },
            { label: 'Beta Cells', value: 'Beta' }
        ];

        this.glucConc = [
            { label: '1mM Glucose', value: '1' },
            { label: '5mM Glucose', value: '5' },
            { label: '10mM Glucose', value: '10' }
        ];

        this.libraryOpts = [
            { label: 'KEGG', value: 'kegg' },
            { label: 'Reactome', value: 'reactome' },
            { label: 'GO Biological Process', value: 'go_bp' },
            { label: 'GO Cellular Component', value: 'go_cc' },
            { label: 'GO Molecular Function', value: 'go_mf' },
            { label: 'PANTHER Biological Process', value: 'go_panthbp' },
            { label: 'PANTHER Cellular Component', value: 'go_panthcc' },
            { label: 'PANTHER Molecular Function', value: 'go_panthmf' },
        ];

    this.metaLabels = [{label: 'Age (years)',value :'donorage'},{label: 'BMI',value : 'bodymassindex'},  {label: 'HbA1c (%)',value : 'hba1c'},{label: 'Cold Ischemic Time (h)',value : 'coldischemiatime'},{label: 'Pancreas weight',value : 'pancreasweight'},{label: 'Digestion time (min)',value : 'digesttime'},{label: 'Total islet equivalents (IEQ)',value : 'totalieq'},{label: 'Trapped (%)',value : 'trappedpercentage'},{label: 'Islet particle index',value : 'isletparticleindex'},{label: 'Insulin content (ug)',value : 'insulincontent'},{label: 'DNA content (ug)',value : 'dnacontent'},{label: 'Insulin:DNA ratio',value : 'insulindnaratio'},{label: 'Insulin per IEQ (ng/IEQ)',value : 'insulinperieq'},{label: 'Non-endocrine cell proportion (computed)',value : 'exo_per'},{label: 'β-cell proportion (of endocrine; computed)',value : 'beta_end'},{label: 'α-cell proportion (of endocrine; computed)',value : 'alpha_end'},{label: 'δ-cell proportion (of endocrine; computed)',value : 'delta_end'},{label: 'γ-cell proportion (of endocrine; computed)',value : 'gamma_end'},{label: 'Culture time (h)',value : 'predistributionculturetime'},{label: 'Percent IEQ recovery after culture (%)',value : 'percentieqrecoverypostculture'},{label: 'Percent IEQ recovery after culture (%)',value : 'pdisletparticleindex'},{label: 'Islet particle index after culture',value : 'pdisletparticleindex'},{label: 'pdinsulincontent',value : 'Insulin content after culture (ug)'},{label: 'pdinsulindnaratio',value : 'Insulin:DNA ratio after culture'},{label: 'Insulin per IEQ after culture (ng/IEQ)',value : 'pdinsulinperieq'},{label: 'Culture time before experiment (days)',value : 'culturetime2'},{label: 'Insulin content (pg/ml)',value : 'total_insulin_content'},{label: 'Secretion at 1 mM glucose (pg/ml)',value : 'insulin_secretion_1'},{label: 'Secretion at 2.8 mM glucose (pg/ml)',value : 'insulin_secretion_2p8'},{label: 'Secretion at 10 mM glucose (pg/ml)',value : 'insulin_secretion_10'},
           {label: 'Secretion at 16.7 mM glucose (pg/ml)',value : 'insulin_secretion_16p7'},{label: 'Stimulation index (1->10 mM glucose)',value : 'gsis_index_1_10'},{label: 'Stimulation index (1->16.7 mM glucose)',value : 'gsis_index_1_16p7'},{label: 'Stimulation index (2.8->16.7 mM glucose)',value : 'gsis_index_2p8_16p7'},{label: 'Basal respiration',value : 'calc_basal_resp'},{label: 'ATP-linked respiration',value : 'calc_atp_resp'},{label: 'Proton leak',value : 'calc_proton_leak'},{label: 'Max glucose response',value : 'calc_max_gluc_resp'},{label: 'Stimulated glucose response',value : 'calc_stim_gluc_resp'},{label: 'Max respiration',value : 'calc_max_resp'},{label: 'Spare capacity',value : 'calc_spare_cap'},{label: '3 mM Glucose baseline secretion (AUC)',value : 'auc_baseline_3mmgluc'},{label: '15 mM Glucose-stimulated peak',value : 'peak_gluc_15mmgluc'},{label: '',value : ''},{label: '15 mM Glucose-stimulated secretion (AUC)',value : 'auc_gluc_15mmgluc'},{label: '6 mM Glucose-stimulated secretion (AUC)',value : 'auc_gluc_6mmgluc'},{label: '30 mM KCl-stimulated secretion, after glucose (AUC)',value : 'auc_gluc_30mmkcl'},{label: '5 mM Leucine-stimulated peak secretion',value : 'peak_leu_5mmleu'},{label: '5 mM Leucine-stimulated secretion (AUC)',value : 'auc_leu_5mmleu'},{label: '5 mM Leucine + 6 mM Glucose-stimulated secretion (AUC)',value : 'auc_leu_5mmleu_6mmgluc'},{label: '30 mM KCl-stimulated secretion, after leucine (AUC)',value : 'auc_leu_30mmkcl'},{label: '1.5 mM Oleate/palmitate-stimulated peak secretion',value : 'peak_olp_1p5mmolp'},{label: '1.5 mM Oleate/palmitate-stimulated secretion (AUC)',value : 'auc_olp_1p5mmolp'},{label: '1.5 mM Oleate/palmitate + 6 mM Glucose-stimulated secretion (AUC)',value : 'auc_olp_1p5mmolp_6mmgluc'},{label: '30 mM KCl-stimulated secretion, after oleate palmetate (AUC)',value : 'auc_olp_30mmkcl'},{label: 'Size (pF)',value : 'cell_size_pF_donor'},
            {label: 'Total exocytosis (fF/pF)',value : 'total_exocytosis_fF_pF_donor'},{label: 'Early (RRP) exocytosis (fF/pF)',value : 'early_exocytosis_fF_pF_donor'},{label: 'Late exocytosis (fF/pF)',value : 'late_exocytosis_fF_pF_donor'},{label: 'calcium_entry_pC_pF_donor',value : 'Calcium entry (pC/pF)'},{label: 'Early calcium current amplitude (pA/pF)',value : 'early_ca_current_pA_pF_donor'},{label: 'Late calcium current amplitude (pA/pF)',value : 'late_ca_current_pA_pF_donor'},{label: 'Sodium current amplitude (pA/pF)',value : 'na_current_amp_pA_pF_donor'},{label: 'Half inactivation sodium current (mV)',value : 'na_half_inactivation_mV_donor'},{label: 'Size (pF)',value : 'cell_size_pF_cell'},{label: 'Total exocytosis (fF/pF)',value : 'total_exocytosis_fF_pF_cell'},{label: 'Early (RRP) exocytosis (fF/pF)',value : 'early_exocytosis_fF_pF_cell'},{label: 'Late exocytosis (fF/pF)',value : 'late_exocytosis_fF_pF_cell'},{label: 'Calcium entry (pC/pF)',value : 'calcium_entry_pC_pF_cell'},{label: 'Early calcium current amplitude (pA/pF)',value : 'early_ca_current_pA_pF_cell'},{label: 'Late calcium current amplitude (pA/pF)',value : 'late_ca_current_pA_pF_cell'},{label: 'Sodium current amplitude (pA/pF)',value : 'na_current_amp_pA_pF_cell'},{label: 'Half inactivation sodium current (mV)',value : 'na_half_inactivation_mV_cell'}]

    }

    ngOnInit() {
        this.subUsrDir = this.global.currentUsrDir.subscribe(data => this.usrDir = data)

        if (this.usrDir === "TESTNA") {
            this.apiService.getLogin().subscribe(data => {
                this.usrDir = data;
                this.global.changeUsrDir(this.usrDir)
            })
        }
    }

    ngOnDestroy() {
        this.subUsrDir.unsubscribe();
    }

    // functions

    loadExample() {
        this.uploadIds = "R391\nR399\nR343\nR200\nR022\nR246\nR212\nR329\nR191\nR173\nR357\nR272\nR286\nR321\nR392\nR395\nR337\nR101\nR227\nR019\n"
    }

    downloadDonorList() {

       let dateTimeStamp = this.getLocalDateTimeStamp();
        this.apiService.getDonorList(this.usrDir).subscribe(data => {
            if (data.includes("RES-OK")) {
                var txtFile = data;
                txtFile = txtFile.replace("RES-OK", "")

                //download file
                this.apiService.getDownload(this.usrDir, txtFile).subscribe(response => {
                    let fileName = response.headers.get('content-disposition')
                        ?.split(';')[1].split('=')[1];
                    let blob: Blob = response.body as Blob;
                    let a = document.createElement('a');
                    txtFile = txtFile.replace(/(\.[^\.]+)$/, `_${dateTimeStamp}$1`);
                    a.download = txtFile;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                })
            } else {
                alert('error: API server failed to return results');
            }
        });
    }

    getFilterSuccess(input: boolean) {
        this.listExists = input;
    }

    onPrimaryChange(event) {
        for (var i = 0; i < this.varData.length; i++) {
            if (this.varData[i].column == event) {
                this.primaryType = this.varData[i].type;
                this.makeComparisonOpts(event);
            }
        }
    }

    onOmicsChange() {
        if (this.selectedOmicsType == 'proc_scrna') {
            this.selectedGroup = "ephys_cell";
            this.primaryType = "cont";
            this.fdr = false;
        } else if (this.selectedOmicsType == 'proc_pbrna') {
            this.selectedGroup = "donor";
            this.fdr = true;
            // if single-cell function is present, remove it
            if(this.variableGroups.length == 10){
                this.variableGroups.pop()
            }
        } else {
            this.selectedGroup = "donor";
            this.fdr = true;
            // if the single-cell function was removed, add them back by re-initializing
            if(this.variableGroups.length == 9){
                this.makeVarsOpts();
            }
        }
        this.makeRankingOpts();
    }

    makeComparisonOpts(metaVar: string) {
        this.varClasses = [];
        this.varClasses.push({ label: '-- Select --', value: null });
        for (var i = 0; i < this.groupData.length; i++) {
            if (this.groupData[i].column == metaVar) {
                this.varClasses.push({ label: this.groupData[i].display, value: this.groupData[i].group });
            }
        }
    }

    makeOmicsOpts() {
        this.omicsType = [];

        // display omics data depending on login type
        for (var i = 0; i < this.omicsData.length; i++) {
            this.omicsType.push({ label: this.omicsData[i].display, value: this.omicsData[i].table });
        }

    }


    makeRankingOpts() {
        if (this.selectedOmicsType == "proc_scrna") {
            this.rankingOpts = [
                { label: 'log2FC/coefficient', value: 'coef' },
            ];
        } else {
            this.rankingOpts = [
                { label: 'log2FC/coefficient', value: 'coef' },
                { label: 'T-statistic', value: 'tstat' },
            ];
        }
    }

    makeCovariatesOpts() {
        this.covariates = [];
        var covGroups = ['Donor Characteristics', 'Organ Characteristics and Processing', 'Isolation Outcomes', 'Cell Culture Outcomes', 'Cell Type Proportions'];
        for (var i = 0; i < covGroups.length; i++) {
            var grpItems = [];
            for (var k = 0; k < this.varData.length; k++) {
                if (this.varData[k].group == covGroups[i]) {
                    grpItems.push({ label: this.varData[k].display, value: this.varData[k].column })
                }
            }
            this.covariates.push({ label: covGroups[i], items: grpItems })
        }

        this.makeRankingOpts();
    }

    makeVarsOpts() {
        this.variableGroups = [];
        const newArray = this.varData.map(function (item) {
            return {
                'group_id': item.group_id,
                'group': item.group,
                'reviewer': item.reviewer,
                'team': item.team
            }
        })
        var map = new Map();
        let uniqueObjects = newArray.filter((obj) => {
            if (map.get(obj.group)) {
                return false;
            }
            map.set(obj.group, obj);
            return true;
        });

        for (var i = 0; i < uniqueObjects.length; i++) {
            this.variableGroups.push({ label: uniqueObjects[i].group, value: uniqueObjects[i].group_id });
        }


        this.varsDonor = [{ label: '-- Select --', value: '--' }];
        this.varsOrgan = [{ label: '-- Select --', value: '--' }];
        this.varsCellPro = [{ label: '-- Select --', value: '--' }];
        this.varsCellCulture = [{ label: '-- Select --', value: '--' }];
        this.varsGRS = [{ label: '-- Select --', value: '--' }];
        this.varsGSIS = [{ label: '-- Select --', value: '--' }];
        this.varsIsolation = [{ label: '-- Select --', value: '--' }];
        this.varsPerifusion = [{ label: '-- Select --', value: '--' }];
        this.varsEphysCell = [{ label: '-- Select --', value: '--' }];
        this.varsEphysDonor = [{ label: '-- Select --', value: '--' }];
        this.varsSeahorse = [{ label: '-- Select --', value: '--' }];

        for (var i = 0; i < this.varData.length; i++) {
            var group = this.varData[i].group_id
            switch (group) {
                case "donor":
                    this.varsDonor.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "organ":
                    this.varsOrgan.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "isolation":
                    this.varsIsolation.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "cell_pro":
                    this.varsCellPro.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "cell_culture":
                    this.varsCellCulture.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "gsis":
                    this.varsGSIS.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "seahorse":
                    this.varsSeahorse.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "perifusion":
                    this.varsPerifusion.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "grs":
                    this.varsGRS.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "ephys_donor":
                    this.varsEphysDonor.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                case "ephys_cell":
                    this.varsEphysCell.push({ label: this.varData[i].display, value: this.varData[i].column });
                    break;
                default:
                    break;
            }
        };
    }

    performRegression() {
        this.loadingDEA = true;
        this.funcResults = null;
        this.funcColNames = null;
        this.funcColumns = null;
        this.deaResults = null;
        this.currentMsg = null;
        this.index = 0;

        if (this.selectedPrimary == "--") {
            this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "You must select a primary metadata for the analysis!" },];
            this.deaResults = null;
            this.loadingDEA = false;
        }

        if (this.selectedOmicsType == "proc_scrna") {
            this.apiService.getSpearmanResults(this.usrDir, this.selectedPrimary, this.pvalThresh.toString(),
                this.donorOpt, this.selectedCell, this.selectedGluc, this.fdr.toString()).subscribe(data => {
                    var res = data.split(";")
                    if (data.includes("RES-OK")) {
                        this.loadingDEA = false;
                        this.virtualFeatures = [];
                        this.sigNum = res[1];
                        this.sigUp = res[2];
                        this.sigDown = res[3];
                        this.nDonors = res[4];
                        this.nCells = res[5];

                        if(this.sigNum == "0"){
                            this.displayZero = true;
                        } else {
                            this.displayZero = false;
                        }

                        var msg = this.nCells + " cells from " + this.nDonors + " donors were included in the analysis."
                        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: msg },];
                        // stream results into table
                        this.http.get(this.baseUrl + "download/" + this.usrDir + "/dea_results.csv", { responseType: 'text' }).subscribe(data => {
                            this.deaResults = csvParse(data, d3.autoType);
                            this.deaColNames = this.deaResults.columns;
                            this.deaColNames[0] = "Feature";
                            this.featureHeader = "Feature";
                            this.featureES = this.deaColNames[1];
                            this.deaColumns = [];
                            for (var i = 1; i < 5; i++) {
                                var header = this.deaColNames[i];
                                header = header.replace('_', '-');
                                this.deaColumns.push({ field: this.deaColNames[i], header: header });
                            }
                        });
                    } else {
                        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "There are fewer than 10 cells with these metadata variables for the selected omics type." },];
                        this.deaResults = null;
                        this.loadingDEA = false;
                    }
                })
        } else {
            var covariates;
            if (this.selectedCovariates == null) {
                covariates = "NA"
            } else {
                covariates = this.selectedCovariates.toString();
            }

            // check parameters
            if (this.class1 == this.class2) {
                this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "You must select two different metadata values for the 'Comparison of Interest'." },];
                this.loadingDEA = false;
                return;
            }

            this.apiService.getRegressionResults(this.usrDir, this.selectedGroup, this.selectedPrimary, this.class2, this.class1,
                covariates, this.selectedOmicsType, this.pvalThresh.toString(), this.donorOpt, this.selectedCell, this.selectedGluc, this.fdr.toString()).subscribe(data => {
                    var res = data.split(";")
                    if (data.includes("RES-OK")) {
                        this.loadingDEA = false;
                        this.virtualFeatures = [];
                        this.sigNum = res[1]
                        this.sigUp = res[2]
                        this.sigDown = res[3]
                        this.nDonors = res[4]

                        if(this.sigNum == "0"){
                            this.displayZero = true;
                        } else {
                            this.displayZero = false;
                        }

                        var msg = this.nDonors + " donors were included in the analysis."
                        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: msg },];
                        // stream results into table
                        this.http.get(this.baseUrl + "download/" + this.usrDir + "/dea_results.csv", { responseType: 'text' }).subscribe(data => {
                            this.deaResults = csvParse(data, d3.autoType);
                            this.deaColNames = this.deaResults.columns;
                            this.deaColNames[0] = "Feature";
                            this.featureHeader = "Feature";
                            this.featureES = this.deaColNames[1];
                            this.deaColumns = [];
                            for (var i = 1; i < 7; i++) {
                                var header = this.deaColNames[i];
                                header = header.replace('_', '-');
                                this.deaColumns.push({ field: this.deaColNames[i], header: header });
                            }
                        });
                    } else {
                        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "There are fewer than 10 donors with these metadata variables for the selected omics type." },];
                        this.deaResults = null;
                        this.loadingDEA = false;
                    }
                })
        }
    }

    downloadDEAResults() {
        this.apiService.getDownload(this.usrDir, "dea_results.csv").subscribe(response => {
            let fileName = response.headers.get('content-disposition')
                ?.split(';')[1].split('=')[1];
            let blob: Blob = response.body as Blob;
            let a = document.createElement('a');
            let dateTimeStamp = this.getLocalDateTimeStamp();
            a.download = "dea_results_"+dateTimeStamp+".csv";
            a.href = window.URL.createObjectURL(blob);
            a.click();
        });
    }
    
    saveAnalysis() {
        this.apiService.getDownload(this.usrDir,  "Saved_Analysis.zip").subscribe(response => {
            let fileName = response.headers.get('content-disposition')
                ?.split(';')[1].split('=')[1];
            let blob: Blob = response.body as Blob;
            let a = document.createElement('a');
            
    let dateTimeStamp = this.getLocalDateTimeStamp();
            a.download = "Saved_Analysis_"+dateTimeStamp+".zip";
            a.href = window.URL.createObjectURL(blob);
            a.click();
        });
    }

    downloadFuncResults() {
        this.apiService.getDownload(this.usrDir, "functional_results.csv").subscribe(response => {
            let fileName = response.headers.get('content-disposition')
                ?.split(';')[1].split('=')[1];
            let blob: Blob = response.body as Blob;
            let a = document.createElement('a');
       let dateTimeStamp = this.getLocalDateTimeStamp();
            a.download = "functional_results_"+dateTimeStamp+".csv";
            a.href = window.URL.createObjectURL(blob);
            a.click();
        });
    }



    plotFeature(event: any, source: string) {

        if(this.selectedOmicsType != "proc_scrna"){
            this.loadingImage = 'yes';
            this.imageToShow = null;
           // this.displayFeature = true;
            this.displayInterFeature = true;
       
            if (source == 'volcano') {
                var res = event.split(";")
                var plotGeneID = res[0];
                this.currentFeature = res[1];
                this.currentDescription = res[2];
            } else {
                this.currentFeature = event.data[this.featureHeader];
                this.currentDescription = event.data.Description;
                var plotGeneID = event.data.Gene_ID;
            } 
          this.apiService.getOmicsFeature(this.usrDir, plotGeneID, this.selectedPrimary, this.selectedOmicsType, this.donorOpt, this.selectedCell, this.selectedGluc).subscribe(response => {
                if (response.includes("RES-OK")) {
                    response = response.replace("RES-OK", "");
                    this.figNm = response
                  let baseUrl = new URL(this.baseUrl);
                   let fullPath = new URL("download/" + this.usrDir + "/df.csv", baseUrl);
    
                 this.http.get(fullPath.toString(), { responseType: 'text' }).subscribe(data => {
                      const parsedData = csvParse(data);
                        this.boxData = parsedData.map(item => ({
                      record: item.record_id,  // Ensure 'record_id' matches your CSV header
                      meta: item.meta,         // Ensure 'meta' matches your CSV header
                      feature: +item.feature   // Ensure 'feature' matches your CSV header and convert it
              }));
                  });
                        this.loadingImage = 'no';
                }
            });
        }
    }

    plotPathway(event: any){
        this.loadingImage = 'yes';
        this.imageToShow = null;
        this.displayPathway = true;

        this.currentPathway = event
        this.apiService.getPathwayHeatmap(this.usrDir, this.currentPathway, this.selectedLibrary, this.selectedPrimary, this.selectedOmicsType, this.selectedGroup, this.selectedCell, this.selectedGluc, this.donorOpt).subscribe(response => {
            if (response.includes("RES-OK")) {
                response = response.replace("RES-OK", "");
                  this.figNm = "pathway_heatmap.png";
                this.apiService.streamImage(this.usrDir, "pathway_heatmap.png").subscribe(response => {
                    this.createImageFromBlob(response);
                    this.loadingImage = 'no';
                }, error => {
                    console.log('error: API server failed to return results');
                    this.loadingImage = 'no';
                });
            }
        })
    }

    clearFeature() {
        this.displayFeature = false;
        this.displayInterFeature = false;
        this.displaySCFeature = false;
        this.displayPathway = false;
        this.selectedFeature = [];
        this.displayInterFeature = false;
        //d3.select('#boxplotContainer svg').remove();

    }

    clearDonorList() {
        this.displayDonor = false;
        this.listExists = false;
    }

    plotSingleCell(geneID: string, symbol: string, name: string) {
        this.loadingImage = 'yes';
        this.imageToShow = null;
        this.displaySCFeature = true;
        this.currentFeature = symbol;
        this.currentDescription = name;

        this.apiService.getSCFeature(this.usrDir, geneID).subscribe(response => {
            if (response.includes("RES-OK")) {
                response = response.replace("RES-OK", "");
                this.figNm = response;
                this.apiService.streamImage(this.usrDir, response).subscribe(response => {
                 this.createImageFromBlob(response);
                    this.loadingImage = 'no';
                }, error => {
                    console.log('error: API server failed to return results');
                    this.loadingImage = 'no';
                });
            } else {
                this.loadingImage = 'no';
                this.displaySCFeature = false;
                this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "This gene was not found in our single-cell database." },];
            }
        });

    }

    autoGSEA() {
        this.funcOpt = "gsea"
        this.index = 2;
        if(this.selectedOmicsType == "proc_scrna"){
            this.selectedRanking = "coef"
            this.performFunctionalAnalysis()
        } else {
            this.selectedRanking = "tstat"
            this.performFunctionalAnalysis()
        }
    }

    performFunctionalAnalysis() {
        this.funcResults = null;
        this.funcColNames = null;
        this.funcColumns = null;
        this.loadingFunc = true;

        if (this.funcOpt == "ora") {
            this.apiService.getORAResults(this.usrDir, this.selectedLibrary, this.fdrFunc.toString(), this.collapsePathways.toString()).subscribe(data => {
                var res = data.split(";")
                console.log(data)
                if (data.includes("RES-OK")) {
                    this.loadingFunc = false;
                    this.sigPathNum = res[1];
                    console.log(this.sigPathNum)
                    // stream results into table
                    this.http.get(this.baseUrl + "download/" + this.usrDir + "/functional_results.csv", { responseType: 'text' }).subscribe(data => {
                        this.funcResults = csvParse(data, d3.autoType);
                        this.funcColNames = this.funcResults.columns;
                        this.funcColumns = [];
                        for (var i = 0; i < 6; i++) {
                            var header = this.funcColNames[i];
                            header = header.replace('_', '-');
                            this.funcColumns.push({ field: this.funcColNames[i], header: header });
                        }

                        // populate ridgeline array
                        this.http.get(this.baseUrl + "download/" + this.usrDir + "/ridgeline.csv", { responseType: 'text' }).subscribe(data => {
                            this.ridgeData = csvParse(data, d3.autoType);
                            if (this.primaryType == "disc") {
                                this.ridgeXLab = "log2FC"
                            } else {
                                this.ridgeXLab = "coefficient"
                            }
                        });
                    });
                } else {
                    this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "Something went wrong with pathway analysis! Are there fewer than 10 significant features?" },];
                    this.loadingFunc = false;
                }
            })
        } else {
            this.apiService.getGSEAResults(this.usrDir, this.selectedLibrary, this.selectedRanking, this.fdrFunc.toString(), 
            this.collapsePathways.toString()).subscribe(data => {
                var res = data.split(";")
                if (res[0] == "RES-OK") {
                    this.loadingFunc = false;
                    this.sigPathNum = res[1];
                    // stream results into table
                    this.http.get(this.baseUrl + "download/" + this.usrDir + "/functional_results.csv", { responseType: 'text' }).subscribe(data => {
                        this.funcResults = csvParse(data, d3.autoType);
                        this.funcColNames = this.funcResults.columns;
                        this.funcColumns = [];
                        for (var i = 0; i < 6; i++) {
                            var header = this.funcColNames[i];
                            header = header.replace('_', '-');
                            this.funcColumns.push({ field: this.funcColNames[i], header: header });
                        }

                        // populate ridgeline array
                        this.http.get(this.baseUrl + "download/" + this.usrDir + "/ridgeline.csv", { responseType: 'text' }).subscribe(data => {
                            this.ridgeData = csvParse(data, d3.autoType);

                            if (this.selectedRanking == "tstat") {
                                this.ridgeXLab = "T-statistic"
                            } else {
                                if (this.primaryType == "disc") {
                                    this.ridgeXLab = "log2FC"
                                } else {
                                    this.ridgeXLab = "coefficient"
                                }
                            }
                        });
                    });

                } else {
                    this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "Something went wrong with pathway analysis!" },];
                    this.loadingFunc = false;
                }
            })
        }

    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.base64Image = reader.result;
            this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

dwlFeature(imgType: string){

    let dateTimeStamp = this.getLocalDateTimeStamp();
 
    let fileName = this.figNm.replace("png", imgType) ;
 this.apiService.getDownload(this.usrDir, fileName).subscribe(response => {
          let contentDisposition = response.headers.get('content-disposition');

    
    if (contentDisposition) { 
           let matches = contentDisposition.match(/filename="?([^"]+)"?/);
        if (matches && matches.length > 1) {
            fileName = matches[1];
        }
    }

    fileName = fileName.replace(/(\.[^\.]+)$/, `_${dateTimeStamp}$1`);

    let blob = new Blob([response.body], { type: imgType });
 
    let a = document.createElement('a');
    a.style.display = 'none';  
    document.body.appendChild(a);  

    a.download = fileName;  
    a.href = window.URL.createObjectURL(blob); 

    a.click(); 
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
 
        });

}



queryDonor(event: any){
    var navURL = '#/donor?recordID=' + event
    console.log(navURL)
    window.open(navURL, '_blank').focus();
}

 getLocalDateTimeStamp() {
    let now = new Date();
    let year = now.getFullYear();
    let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let day = String(now.getDate()).padStart(2, '0');
    let hours = String(now.getHours()).padStart(2, '0');
    let minutes = String(now.getMinutes()).padStart(2, '0');
    let seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}`;
}
}
