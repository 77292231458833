import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message, SelectItem, SelectItemGroup } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { RestAPIService } from 'src/app/service/rest-api.service';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Output, EventEmitter } from '@angular/core';

interface City {
    name: string,
    code: string
}

@Component({
    selector: 'app-scfilter',
    templateUrl: './app.scfilter.component.html',
})

export class AppSCFilterComponent implements OnInit, OnDestroy {

    @Output() emitFilterSuccess = new EventEmitter<boolean>();
    listExist: boolean = false;

    // usrDir variable
    subUsrDir: Subscription;
    usrDir: string;

    // view image variables
    loadingImage: string = "no";

    // API parameter variables
    uploadIds: string;
    donorIds: string = 'NA';
    donorRes: string;

    // main page filter boolean
    filterSex: boolean = false;
    filterDiagnosis: boolean = false;
    filterAge: boolean = false;
    filterBMI: boolean = false;
    filterHba1c: boolean = false;

    // main page filter parameters
    selectedSex: string[] = ['Male', 'Female'];
    selectedDtype: string[] = ['None', 'Type1', 'Type2'];
    ageValues: number[] = [20, 80];
    bmiValues: number[] = [20, 45];
    hba1cValues: number[] = [10, 12];

    // data type paramters
    donorSelectOptions: any[];
    donorSelect: string = "filter";

    IDType: SelectItem[];
    selectedIDType: string = "redcap";

    // dialog display booleans
    displayAge: boolean;
    displayBMI: boolean;
    displayHba1c: boolean;

    zipFile: string;
    msgText: string;
    currentMsg: Message[];

    constructor(public appMain: AppMainComponent, private apiService: RestAPIService, private domSanitizer: DomSanitizer, private global: GlobalVarsService) {

        this.IDType = [
            { label: 'Donor ID', value: 'redcap' },
            { label: 'RRID', value: 'rrid' }
        ];

        this.donorSelectOptions = [{ label: 'Apply Filters', value: 'filter' }, { label: 'Upload List', value: 'upload' }];

    }

    ngOnInit() {
        this.subUsrDir = this.global.currentUsrDir.subscribe(data => this.usrDir = data)

        if (this.usrDir === "NA") {
            this.apiService.getLogin().subscribe(data => {
                this.usrDir = data;
                this.global.changeUsrDir(this.usrDir)
            })
        }
    }

    ngOnDestroy() {
        this.subUsrDir.unsubscribe();
    }


    // define functions
    getDonors(method: string) {
        if (method === "filter") {
            this.loadingImage = 'yes';
            const data = {
                usrDir: this.usrDir,
                omics: "singlecell",
                sex: this.filterSex.toString() + "|" + this.selectedSex.toString(),
                diagnosis: this.filterDiagnosis.toString() + "|" + this.selectedDtype.toString(),
                age: this.filterAge.toString() + "|" + this.ageValues.toString(),
                bmi: this.filterBMI.toString() + "|" + this.bmiValues.toString(),
                hba1c: this.filterHba1c.toString() + "|" + this.hba1cValues.toString()
            }

            this.apiService.getDonors(data).subscribe(data => {
                this.donorRes = data;
                if (this.donorRes.includes("RES-OK")) {
                    this.donorRes = this.donorRes.replace("RES-OK", "")
                    if (this.donorRes === "0") {
                        this.msgText = "There were no donors that passed all of the specified filters!"
                        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: this.msgText },];
                        this.listExist = false;
                        this.toggleSuccess(false);
                    } else {
                        this.msgText = this.donorRes + " donors passed all of the specified filters."
                        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: this.msgText },];
                        this.listExist = true;
                        this.toggleSuccess(true);
                    }
                    this.loadingImage = 'no';
                } else {
                    this.currentMsg = [{ severity: 'error', summary: 'Error', detail: 'The API server failed to return results! Please contact us.' },];
                    this.listExist = false;
                    this.toggleSuccess(false);
                    this.loadingImage = 'no';
                }
            })
        } else {
            this.loadingImage = 'yes';
            this.donorIds = this.uploadIds.replaceAll("\n", ",")
            if (this.donorIds.slice(-1) === ",") {
                this.donorIds = this.donorIds.slice(0, -1)
            }

            this.apiService.postUploadDonors(this.usrDir, this.donorIds, this.selectedIDType).subscribe(data => {
                this.donorRes = data;
                if (this.donorRes.includes("RES-OK")) {
                    this.donorRes = this.donorRes.replace("RES-OK", "")
                    if (this.donorRes === "0") {
                        this.msgText = "None of the uploaded IDs matched to our database!"
                        this.listExist = false;
                        this.toggleSuccess(false);
                        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: this.msgText },];
                    } else {
                        this.msgText = this.donorRes + " uploaded IDs matched to our database."
                        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: this.msgText },];
                        this.listExist = true;
                        this.toggleSuccess(true);
                    }
                    this.loadingImage = 'no';
                } else {
                    this.currentMsg = [{ severity: 'error', summary: 'Error', detail: 'The API server failed to return results! Please contact us.' },];
                    this.listExist = false;
                    this.toggleSuccess(false);
                    this.loadingImage = 'no';
                }
            })
            this.loadingImage = 'no'
        }
    }

    downloadDonorList() {
        this.apiService.getDonorList(this.usrDir).subscribe(data => {
            if (data.includes("RES-OK")) {
                var txtFile = data;
                txtFile = txtFile.replace("RES-OK", "")

                //download file
                this.apiService.getDownload(this.usrDir, txtFile).subscribe(response => {
                    let fileName = response.headers.get('content-disposition')
                        ?.split(';')[1].split('=')[1];
                    console.log(fileName);
                    let blob: Blob = response.body as Blob;
                    let a = document.createElement('a');
                    a.download = txtFile;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                })
            } else {
                alert('error: API server failed to return results');
            }
        });
    }

    loadExample() {
        this.uploadIds = "R391\nR399\nR343\nR200\nR022\nR246\nR212\nR329\nR191\nR173\nR357\nR272\nR286\nR321\nR392\nR395\nR337\nR101\nR227\nR019\n"
    }

    toggleSuccess(value: boolean) {
        this.emitFilterSuccess.emit(value);
    }
}
