import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { RestAPIService } from '../service/rest-api.service';
import { GlobalVarsService } from '../service/global-vars.service';
import { Message } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { csvParse } from "d3";
import { Router, ActivatedRoute } from '@angular/router';
import * as d3 from "d3";

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-feature',
  templateUrl: './app.feature.component.html',
})
export class AppFeatureComponent implements OnInit, OnDestroy {

  // global variables
  subUsrDir: Subscription;
  subBaseUrl: Subscription;

  baseUrl: string;
  usrDir: string;
  currentMsg: Message[];

  // component variables
  loadingImage: string = "no";
  host: string = "public";
  base64Image: any;
  imageToShow: any;
  pageURL: string;

  // variables for search
  searchNames: any[] | undefined;
  selectedSearchName: any;
  filteredSearchNames: any[] | undefined;
  searchResults: any;
  searchColumns: any[];
  searchColNames: any[];

  // URL parameters
  urlGeneID: string;

  displaySCFeature: boolean = false;
  currentFeature: string;
  currentDescription: string;

  displayFeature: boolean = false;
  loadingFeature: boolean = false;
  boxData: any[];
  metaLabels: any[];
  figNm: string;
  selectedOmicsType: string;
  selectedPrimary: string;
  primaryType: string='cont';
  discMeta: any[];

  constructor(private apiService: RestAPIService, private domSanitizer: DomSanitizer,
    private global: GlobalVarsService, private http: HttpClient, private route: ActivatedRoute) {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => this.baseUrl = data)
    console.log(this.subBaseUrl)
    this.http.get(this.baseUrl + "download/humanislets/display_interface-feature_search_names.csv", { responseType: 'text' }).subscribe(data => {
      this.searchNames = csvParse(data);
    });
    this.metaLabels = [{label: 'Age (years)',value :'donorage'},{label: 'BMI',value : 'bodymassindex'},  {label: 'HbA1c (%)',value : 'hba1c'},{label: 'Cold Ischemic Time (h)',value : 'coldischemiatime'},{label: 'Pancreas weight',value : 'pancreasweight'},{label: 'Digestion time (min)',value : 'digesttime'},{label: 'Total islet equivalents (IEQ)',value : 'totalieq'},{label: 'Trapped (%)',value : 'trappedpercentage'},{label: 'Islet particle index',value : 'isletparticleindex'},{label: 'Insulin content (ug)',value : 'insulincontent'},{label: 'DNA content (ug)',value : 'dnacontent'},{label: 'Insulin:DNA ratio',value : 'insulindnaratio'},{label: 'Insulin per IEQ (ng/IEQ)',value : 'insulinperieq'},{label: 'Non-endocrine cell proportion (computed)',value : 'exo_per'},{label: 'β-cell proportion (of endocrine; computed)',value : 'beta_end'},{label: 'α-cell proportion (of endocrine; computed)',value : 'alpha_end'},{label: 'δ-cell proportion (of endocrine; computed)',value : 'delta_end'},{label: 'γ-cell proportion (of endocrine; computed)',value : 'gamma_end'},{label: 'Culture time (h)',value : 'predistributionculturetime'},{label: 'Percent IEQ recovery after culture (%)',value : 'percentieqrecoverypostculture'},{label: 'Percent IEQ recovery after culture (%)',value : 'pdisletparticleindex'},{label: 'Islet particle index after culture',value : 'pdisletparticleindex'},{label: 'pdinsulincontent',value : 'Insulin content after culture (ug)'},{label: 'pdinsulindnaratio',value : 'Insulin:DNA ratio after culture'},{label: 'Insulin per IEQ after culture (ng/IEQ)',value : 'pdinsulinperieq'},{label: 'Culture time before experiment (days)',value : 'culturetime2'},{label: 'Insulin content (pg/ml)',value : 'total_insulin_content'},{label: 'Secretion at 1 mM glucose (pg/ml)',value : 'insulin_secretion_1'},{label: 'Secretion at 2.8 mM glucose (pg/ml)',value : 'insulin_secretion_2p8'},{label: 'Secretion at 10 mM glucose (pg/ml)',value : 'insulin_secretion_10'},
           {label: 'Secretion at 16.7 mM glucose (pg/ml)',value : 'insulin_secretion_16p7'},{label: 'Stimulation index (1->10 mM glucose)',value : 'gsis_index_1_10'},{label: 'Stimulation index (1->16.7 mM glucose)',value : 'gsis_index_1_16p7'},{label: 'Stimulation index (2.8->16.7 mM glucose)',value : 'gsis_index_2p8_16p7'},{label: 'Basal respiration',value : 'calc_basal_resp'},{label: 'ATP-linked respiration',value : 'calc_atp_resp'},{label: 'Proton leak',value : 'calc_proton_leak'},{label: 'Max glucose response',value : 'calc_max_gluc_resp'},{label: 'Stimulated glucose response',value : 'calc_stim_gluc_resp'},{label: 'Max respiration',value : 'calc_max_resp'},{label: 'Spare capacity',value : 'calc_spare_cap'},{label: '3 mM Glucose baseline secretion (AUC)',value : 'auc_baseline_3mmgluc'},{label: '15 mM Glucose-stimulated peak',value : 'peak_gluc_15mmgluc'},{label: '',value : ''},{label: '15 mM Glucose-stimulated secretion (AUC)',value : 'auc_gluc_15mmgluc'},{label: '6 mM Glucose-stimulated secretion (AUC)',value : 'auc_gluc_6mmgluc'},{label: '30 mM KCl-stimulated secretion, after glucose (AUC)',value : 'auc_gluc_30mmkcl'},{label: '5 mM Leucine-stimulated peak secretion',value : 'peak_leu_5mmleu'},{label: '5 mM Leucine-stimulated secretion (AUC)',value : 'auc_leu_5mmleu'},{label: '5 mM Leucine + 6 mM Glucose-stimulated secretion (AUC)',value : 'auc_leu_5mmleu_6mmgluc'},{label: '30 mM KCl-stimulated secretion, after leucine (AUC)',value : 'auc_leu_30mmkcl'},{label: '1.5 mM Oleate/palmitate-stimulated peak secretion',value : 'peak_olp_1p5mmolp'},{label: '1.5 mM Oleate/palmitate-stimulated secretion (AUC)',value : 'auc_olp_1p5mmolp'},{label: '1.5 mM Oleate/palmitate + 6 mM Glucose-stimulated secretion (AUC)',value : 'auc_olp_1p5mmolp_6mmgluc'},{label: '30 mM KCl-stimulated secretion, after oleate palmetate (AUC)',value : 'auc_olp_30mmkcl'},{label: 'Size (pF)',value : 'cell_size_pF_donor'},
            {label: 'Total exocytosis (fF/pF)',value : 'total_exocytosis_fF_pF_donor'},{label: 'Early (RRP) exocytosis (fF/pF)',value : 'early_exocytosis_fF_pF_donor'},{label: 'Late exocytosis (fF/pF)',value : 'late_exocytosis_fF_pF_donor'},{label: 'calcium_entry_pC_pF_donor',value : 'Calcium entry (pC/pF)'},{label: 'Early calcium current amplitude (pA/pF)',value : 'early_ca_current_pA_pF_donor'},{label: 'Late calcium current amplitude (pA/pF)',value : 'late_ca_current_pA_pF_donor'},{label: 'Sodium current amplitude (pA/pF)',value : 'na_current_amp_pA_pF_donor'},{label: 'Half inactivation sodium current (mV)',value : 'na_half_inactivation_mV_donor'},{label: 'Size (pF)',value : 'cell_size_pF_cell'},{label: 'Total exocytosis (fF/pF)',value : 'total_exocytosis_fF_pF_cell'},{label: 'Early (RRP) exocytosis (fF/pF)',value : 'early_exocytosis_fF_pF_cell'},{label: 'Late exocytosis (fF/pF)',value : 'late_exocytosis_fF_pF_cell'},{label: 'Calcium entry (pC/pF)',value : 'calcium_entry_pC_pF_cell'},{label: 'Early calcium current amplitude (pA/pF)',value : 'early_ca_current_pA_pF_cell'},{label: 'Late calcium current amplitude (pA/pF)',value : 'late_ca_current_pA_pF_cell'},{label: 'Sodium current amplitude (pA/pF)',value : 'na_current_amp_pA_pF_cell'},{label: 'Half inactivation sodium current (mV)',value : 'na_half_inactivation_mV_cell'}]
     this.discMeta = ['donorsex','donationtype','hla_a2','diagnosis','diagnosis_computed','fatinfiltration','pancreasconsistency','collagenase','collagenasetype'];
  
}
  ngOnInit() {
    this.subUsrDir = this.global.currentUsrDir.subscribe(data => this.usrDir = data)

    if (this.usrDir === "NA") {
      this.apiService.getLogin().subscribe(data => {
        this.usrDir = data;
        this.global.changeUsrDir(this.usrDir)
      })
    }

    const currentUrl = window.location.href;
    if (currentUrl.includes("localhost")) {
      this.host = "local";
      this.pageURL = "http://localhost:4200/#/"
    } else {
      this.host = "public";
      this.pageURL = "https://www.humanislets.com/#/"
    }

    this.route.queryParams.subscribe(params => {
      this.urlGeneID = params['varID'];
      console.log("URL geneID: " + this.urlGeneID);

      if(this.urlGeneID){
        if (this.usrDir === "NA") {
          this.apiService.getLogin().subscribe(data => {
            this.usrDir = data;
            this.global.changeUsrDir(this.usrDir)
            this.loadingFeature = true;
            this.apiService.getSearchResults(this.usrDir, this.urlGeneID, "omics", "NA").subscribe(data => {

              if (data == "RES-OK") {
  
                this.currentMsg = [{ severity: 'success', summary: 'Success', detail: "The top results are displayed below." },];
  
                // stream results into table
                this.http.get(this.baseUrl + "download/" + this.usrDir + "/search_results.csv", { responseType: 'text' }).subscribe(data => {
                  this.searchResults = csvParse(data, d3.autoType);
                  this.searchColNames = this.searchResults.columns;
                  this.searchColNames[0] = "Feature";
  
                  this.searchColumns = [];
                  for (var i = 3; i < 6; i++) {
                    var header = this.searchColNames[i].replace('_', '-');
                    this.searchColumns.push({ field: this.searchColNames[i], header: header });
                  }

                  this.filterQueryUrl(this.urlGeneID)
                  this.loadingFeature = false;
                });
              } else {
                this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "We could not find any results matching your search query." },];
                this.searchResults = null;
                this.loadingFeature = false;
              }
            });
          })
        } else {
          this.loadingFeature = true;
          this.apiService.getSearchResults(this.usrDir, this.urlGeneID, "omics", "NA").subscribe(data => {
            if (data == "RES-OK") {
  
              this.currentMsg = [{ severity: 'success', summary: 'Success', detail: "The top results are displayed below." },];
  
              // stream results into table
              this.http.get(this.baseUrl + "download/" + this.usrDir + "/search_results.csv", { responseType: 'text' }).subscribe(data => {
                this.searchResults = csvParse(data, d3.autoType);
                this.searchColNames = this.searchResults.columns;
                this.searchColNames[0] = "Feature";
  
                this.searchColumns = [];
                for (var i = 3; i < 6; i++) {
                  var header = this.searchColNames[i].replace('_', '-');
                  this.searchColumns.push({ field: this.searchColNames[i], header: header });
                }
                this.filterQueryUrl(this.urlGeneID)
                this.loadingFeature = false;
              });
            } else {
              this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "We could not find any results matching your search query." },];
              this.searchResults = null;
              this.loadingFeature = false;
            }
          });
        }
      }
    })

  }

  ngOnDestroy() {
    this.subUsrDir.unsubscribe();
  }


  /// functions

  performSearch(query: any) {
    this.loadingFeature = true;
    this.apiService.getSearchResults(this.usrDir, query.ID, query.type, query.Contrast).subscribe(data => {
      if (data == "RES-OK") {

        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: "The top results are displayed below." },];

        // stream results into table
        this.http.get(this.baseUrl + "download/" + this.usrDir + "/search_results.csv", { responseType: 'text' }).subscribe(data => {
          this.searchResults = csvParse(data, d3.autoType);
          this.searchColNames = this.searchResults.columns;
          this.searchColNames[0] = "Feature";

          this.searchColumns = [];
          for (var i = 3; i < 6; i++) {
            var header = this.searchColNames[i].replace('_', '-');
            this.searchColumns.push({ field: this.searchColNames[i], header: header });
          }

          this.loadingFeature = false;
        });
      } else {
        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "We could not find any results matching your search query." },];
        this.searchResults = null;
        this.loadingFeature = false;
      }
    })
  }

  downloadFeatureResults() {
    this.apiService.getDownload(this.usrDir, "search_results.csv").subscribe(response => {
      let fileName = response.headers.get('content-disposition')
        ?.split(';')[1].split('=')[1];
       let dateTimeStamp = this.getLocalDateTimeStamp();
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a')
      a.download = "search_results_"+dateTimeStamp+".csv";
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }

  filterQueries(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = new RegExp(event.query, 'i'); // 'i' flag for case-insensitive search

    for (let i = 0; i < (this.searchNames as any[]).length; i++) {
      let searchName = (this.searchNames as any[])[i];
      let label = searchName.label;
      if (query.test(label)) {
        filtered.push(searchName);
      }
    }
    this.filteredSearchNames = filtered;
  }

  filterQueryUrl(geneID: string) {
    let filtered: any[] = [];
    let query = new RegExp("Entrez: " + geneID + " - ")

    for (let i = 0; i < (this.searchNames as any[]).length; i++) {
      let searchName = (this.searchNames as any[])[i];
      let label = searchName.label;
      if (query.test(label)) {
        filtered.push(searchName);
      }
    }
    this.selectedSearchName = filtered[0].label;
    console.log(filtered[0].label)
  }


  plotSingleCell(geneID: string, symbol: string, name: string) {
    this.loadingImage = 'yes';
    this.imageToShow = null;
    this.displaySCFeature = true;
    this.currentFeature = symbol;
    this.currentDescription = name;

    this.apiService.getSCFeature(this.usrDir, geneID).subscribe(response => {
      if (response.includes("RES-OK")) {
        response = response.replace("RES-OK", "");
        this.figNm = response;
        this.apiService.streamImage(this.usrDir, response).subscribe(response => {
          this.createImageFromBlob(response);
          this.loadingImage = 'no';
        }, error => {
          console.log('error: API server failed to return results');
          this.loadingImage = 'no';
        });
      } else {
        this.loadingImage = 'no';
        this.displaySCFeature = false;
        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: "This gene was not found in our single-cell database." },];
      }
    });

  }

  plotFeature(event: any) {

    this.loadingImage = 'yes';
    this.imageToShow = null;
    this.displayFeature = true;

    this.currentFeature = event.data["Feature"];
    this.currentDescription = event.data.Description;
    var plotGeneID = event.data["Gene ID"];
    var cell;
    var glucose;
    var metadataVar;
    var omicsType;
    console.log(event.data)
    this.selectedPrimary = event.data["Metadata ID"]
    if(this.discMeta.includes(this.selectedPrimary) ){
     this.primaryType = "disc"

    }else{
     this.primaryType = "cont"
    }
  
 
    if (event.data["Metadata Group ID"] == "ephys_cell" || event.data["Metadata Group ID"] == "ephys_donor") {
      if (event.data["Metadata ID"].includes("Alpha")) {
        cell = "Alpha"
        glucose = event.data["Metadata ID"].split("_Alpha_")[1].replace("mM", "");
        metadataVar = event.data["Metadata ID"].split("_Alpha_")[0]
      } else {
        cell = "Beta"
        glucose = event.data["Metadata ID"].split("_Beta_")[1].replace("mM", "");
        metadataVar = event.data["Metadata ID"].split("_Beta_")[0]
      }

      if (event.data["Metadata Group ID"] == "ephys_cell") {
        omicsType = "proc_scrna"
      } else {
        omicsType = event.data["Omics ID"]
      }
     this.selectedOmicsType = omicsType;
    
      this.apiService.getOmicsFeature(this.usrDir, plotGeneID, metadataVar, omicsType, "all", cell, glucose).subscribe(response => {
        if (response.includes("RES-OK")) {
          response = response.replace("RES-OK", "");
          this.figNm = response;
           let baseUrl = new URL(this.baseUrl);
               let fullPath = new URL("download/" + this.usrDir + "/df.csv", baseUrl);

             this.http.get(fullPath.toString(), { responseType: 'text' }).subscribe(data => {
                  const parsedData = csvParse(data);
                    this.boxData = parsedData.map(item => ({
                  record: item.record_id,  // Ensure 'record_id' matches your CSV header
                  meta: item.meta,         // Ensure 'meta' matches your CSV header
                  feature: +item.feature   // Ensure 'feature' matches your CSV header and convert it
          }));
              });
          this.loadingImage = 'no';
          /*this.apiService.streamImage(this.usrDir, response).subscribe(response => {
            this.createImageFromBlob(response);
            this.loadingImage = 'no';
          }, error => {
            console.log('error: API server failed to return results');
            this.loadingImage = 'no';
          });*/
        }
      });

    } else {
       this.selectedOmicsType = event.data["Omics ID"];
      this.apiService.getOmicsFeature(this.usrDir, plotGeneID, event.data["Metadata ID"], event.data["Omics ID"], "all", "NA", "NA").subscribe(response => {
        if (response.includes("RES-OK")) {
          response = response.replace("RES-OK", "");
                this.figNm = response;
          let baseUrl = new URL(this.baseUrl);
               let fullPath = new URL("download/" + this.usrDir + "/df.csv", baseUrl);

             this.http.get(fullPath.toString(), { responseType: 'text' }).subscribe(data => {
                  const parsedData = csvParse(data);
                    this.boxData = parsedData.map(item => ({
                  record: item.record_id,  // Ensure 'record_id' matches your CSV header
                  meta: item.meta,         // Ensure 'meta' matches your CSV header
                  feature: +item.feature   // Ensure 'feature' matches your CSV header and convert it
          }));
              });
      this.loadingImage = 'no';

        /*  this.apiService.streamImage(this.usrDir, response).subscribe(response => {
            this.createImageFromBlob(response);
            this.loadingImage = 'no';
          }, error => {
            console.log('error: API server failed to return results');
            this.loadingImage = 'no';
          });*/
        }
      });
    }
  }

  clearFeature() {
    this.displaySCFeature = false;
    this.displayFeature = false;
  }


  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.base64Image = reader.result;
      this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

dwlFeature(imgType: string){
 console.log(this.figNm)
    let fileName = this.figNm.replace("png",imgType);  
 let dateTimeStamp = this.getLocalDateTimeStamp();
 this.apiService.getDownload(this.usrDir, fileName).subscribe(response => {
          let contentDisposition = response.headers.get('content-disposition');

    
    if (contentDisposition) { 
           let matches = contentDisposition.match(/filename="?([^"]+)"?/);
        if (matches && matches.length > 1) {
            fileName = matches[1];
        }
    }
       fileName = fileName.replace(/(\.[^\.]+)$/, `_${dateTimeStamp}$1`);
    let blob = new Blob([response.body], { type: imgType });
 
    let a = document.createElement('a');
    a.style.display = 'none';  
    document.body.appendChild(a);  

    a.download = fileName;  
    a.href = window.URL.createObjectURL(blob); 

    a.click(); 
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
 
        });

}
queryDonor(event: any){
  var navURL = '#/donor?recordID=' + event
  console.log(navURL)
  window.open(navURL, '_blank').focus();
}
getLocalDateTimeStamp() {
    let now = new Date();
    let year = now.getFullYear();
    let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let day = String(now.getDate()).padStart(2, '0');
    let hours = String(now.getHours()).padStart(2, '0');
    let minutes = String(now.getMinutes()).padStart(2, '0');
    let seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}`;
}

}
