
import { Component, OnInit, ViewEncapsulation, OnChanges, Input, ElementRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as d3 from "d3";
import { SelectItemGroup } from 'primeng/api';

@Component({
    selector: 'app-boxplot',
    templateUrl: './app.boxplot.component.html',
    styleUrls: ['./app.boxplot.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppBoxplotComponent implements OnInit, OnChanges {
    @Input() boxData: any[];
    @Input() primaryType: string;
    @Input() selectedOmicsType: string;
    @Input() currentFeature: string;
    @Input() selectedPrimary: string;
    @Input() metaLabels: any[];
    @Output() newItemEvent = new EventEmitter<string>();
    private svg;
    private tooltip: any;
    private margin = { top: 5, right: 20, bottom: 50, left: 60 };
    private width = 400 - this.margin.left - this.margin.right;
    private height = 350 - this.margin.top - this.margin.bottom;
    private data: any[];


    constructor(private element: ElementRef) {
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {

        const existingSvg = d3.select(this.element.nativeElement).select('.boxplotContainer svg');
        if (!existingSvg.empty()) existingSvg.remove(); 
        d3.select(".scatterPlot").select(".chart-tooltip").remove();
        this.createSvg();
     
        if (this.primaryType == "disc") {
            this.drawViolinPlot();
        } else {
            this.drawScatter();

        }

    }

    private createSvg() {
        d3.select(this.element.nativeElement).select('svg').remove(); 
        this.svg = d3.select(this.element.nativeElement)
            .select('.boxplotContainer')
            .append('svg')
            .attr('width', this.width + this.margin.left + this.margin.right)
            .attr('height', this.height + this.margin.top + this.margin.bottom)
            .append('g')
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
        this.tooltip = d3.select(this.element.nativeElement)
            .select('.boxplotContainer')
            .append("div")
            .classed("chart-tooltip", true)
            .style("display", "none");

    }

    drawViolinPlot() {

        if (!this.boxData) return;
        let groupedData = d3.group(this.boxData, d => d.meta);

        this.data = Array.from(groupedData, ([meta, values]) => ({
            meta: meta,
            values: values.map(d => d.feature)
        }));

        var fillScale = d3.scaleOrdinal().range(["#FFB733", "#87CEEB", "#93c464"])

        var normal = d3.randomNormal()
        var histoChart = d3.histogram();
        const allValues = this.flatten(this.data.map(d => d.values));
        const mean = d3.mean(allValues);
        const low = d3.min(allValues);
        const high = d3.max(allValues);

        var thresholds = d3.range(low, high, (high - low) / 10);
        thresholds.push(high);

        var x = d3.scaleBand()
            .range([0, this.width])
            .domain(this.boxData.map(d => d.meta))
            .padding(0.05);

        this.svg.append("g")
            .attr("transform", "translate(0," + this.height + ")")
            .call(d3.axisBottom(x))
            .style("font-size", "15px");

        var y = d3.scaleLinear()
            .domain([low, high]) 
            .range([this.height - 20, 20]);

        this.svg.append("g")
            .attr("class", "y axis")
            .attr("transform", "translate(0, 0)")
            .call(d3.axisLeft(y)).style("font-size", "12px");


        histoChart
            .domain(y.domain() as [number, number])
            .thresholds(y.ticks(10))
            .value(d => d)


        var area = d3.area()
            .x0(d => -d.length)
            .x1(d => d.length)
            .y(d => d['x0'])
            .curve(d3.curveCatmullRom)

        // Create a mapping function to transform bins to area points
        function prepareAreaData(bins) {
            return bins.map(bin => ({
                x0: bin.x0,  
                x1: bin.x1, 
                length: bin.length  
            }));
        }

        var areaGenerator = d3.area()
            .x0(d => -d.length)
            .x1(d => d.length)
            .y(d => y(d['x0']))
            .curve(d3.curveCatmullRom);


        this.data.forEach((category, index) => {
            const bins = histoChart(category.values);
            const areaData = prepareAreaData(bins);

            this.svg.append("g")
                .attr("class", "violin")
                .attr("transform", `translate(${x(category.meta) + x.bandwidth() / 2}, 0)`)
                .append("path")
                .datum(areaData)
                .attr("d", areaGenerator)
                .style("fill", fillScale(category.meta))
                .style("stroke", fillScale(category.meta))
                .style('stroke-width', '0.2px');

            const medianValue = d3.quantile(category.values, 0.5);

            this.svg.append('line')
                .attr('x1', x(category.meta) + x.bandwidth() / 4)
                .attr('x2', x(category.meta) + 3 * x.bandwidth() / 4)
                .attr('y1', y(medianValue))
                .attr('y2', y(medianValue))
                .style('stroke', 'red')
                .style('stroke-width', '2px');
        });


        this.boxData.forEach(point => {
            var thisRef = this;
            this.svg.append('circle')
                .attr('cx', x(point.meta) + Math.random() * x.bandwidth() / 2 + x.bandwidth() / 4)
                .attr('cy', y(point.feature))
                .attr('r', 3)
                .style('fill', 'darkgrey')
                .style('opacity', 0.7)
                .style('stroke', 'black')
                .on('mouseover', (event) => {
                    d3.select(event.currentTarget).transition()
                        .duration(100)
                        .attr("r", 5)
                        .style("fill", "red");
                    var coords = d3.pointer(event);
                    d3.select('.chart-tooltip')
                        .style("left", (coords[0] + 100) + "px")
                        .style("top", (coords[1] + 100) + "px")
                        .style("display", "block")
                        .style("visibility", "visible")
                        .text(point.record);
                })
                .on('mouseout', (event) => {
                    d3.select(event.currentTarget).transition()
                        .duration(200)
                        .attr("r", 3)
                        .style("fill", "darkgrey");
                    d3.select('.chart-tooltip')
                        .style("visibility", "hidden");;
                })
                .on('click', (event) => {
                     thisRef.newItemEvent.emit(point.record);
                });
        });


    }

    drawScatter() {


        let reorderData = this.boxData.sort((a, b) => {
            const metaA = +a.meta;
            const metaB = +b.meta;
            return metaA - metaB;
        });

        var allmetas = this.boxData.map(d => {
            let num = +d.meta;
            if (isNaN(num)) {
                return null;
            }
            return num;
        }).filter(num => num !== null);


        const x = d3.scaleLinear()
            .domain([d3.min(allmetas) * 0.95, d3.max(allmetas) * 1.05])
            .range([0, this.width]);

        var allvalues = this.boxData.map(d => +d.feature);


        const y = d3.scaleLinear()
            .domain([d3.min(allvalues), d3.max(allvalues)])
            .range([this.height, 0]);

        // Axes
        this.svg.append('g')
            .attr('transform', `translate(0,${this.height})`)
            .call(d3.axisBottom(x));

        this.svg.append('g')
            .call(d3.axisLeft(y));



        const xlabel = this.findLabelByValue(this.metaLabels, this.selectedPrimary);

        this.svg.append("text")
            .attr("text-anchor", "middle")
            .attr("x", this.width / 2)
            .attr("y", this.height + this.margin.top + 35)
            .text(xlabel)
            .style('font-size', "15px");

        this.svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("y", -this.margin.left + 20)
            .attr("x", -this.margin.top - 120)
            .text(this.currentFeature)
            .style('font-size', "15px");

        if (this.selectedOmicsType == "proc_scrna") {
            var thisRef = this;
            this.svg.selectAll("dot")
                .data(this.boxData)
                .enter().append("circle")
                .attr("cx", function (d) { return x(d.meta); })
                .attr("cy", function (d) { return y(d.feature); })
                .attr("r", 4)
                .style("fill", "#grey")
                .attr("opacity", 0.5)
                .style("stroke", "#darkgrey")
                .on('mouseover', (event, d) => {
                    d3.select(event.currentTarget).transition()
                        .duration(100)
                        .attr("r", 6)
                        .style("fill", "red")
                        ;
                })
                .on('mouseout', (event) => {
                    d3.select(event.currentTarget).transition()
                        .duration(200)
                        .attr("r", 4)
                        .style("fill", "black");
                    d3.select('.chart-tooltip')
                        .style("visibility", "hidden");;
                });
            ;

        } else {

            let n = reorderData.length;
            let sum_x = d3.sum(reorderData, d => d.meta);
            let sum_y = d3.sum(reorderData, d => d.feature);
            let sum_xy = d3.sum(reorderData, d => d.meta * d.feature);
            let sum_xx = d3.sum(reorderData, d => d.meta * d.meta);
            //let meanX = d3.mean(reorderData, d => d.meta);
            //let meanY = d3.mean(reorderData, d => d.feature);

            // Calculate slope (m) and y-intercept (b)
            let m = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
            let b = (sum_y - m * sum_x) / n;
            let residuals = reorderData.map(d => (d.feature - (m * d.meta + b)));
            let rss = residuals.reduce((acc, r) => acc + r * r, 0);
            let se = Math.sqrt(rss / (reorderData.length - 2)) / Math.sqrt(reorderData.length);

            let xExtent = d3.extent(reorderData, d => d.meta);
            let linePoints = reorderData.map(d => ({
                meta: d.meta,
                value: m * d.meta + b,
                lower: (m * d.meta + b) - 1.96 * se,   
                upper: (m * d.meta + b) + 1.96 * se   
            }));
        

            let lineGenerator = d3.line()
                .x(d => x(d['meta']))
                .y(d => y(d['value']));


            let areaGenerator = d3.area()
                .x(d => x(d['meta']))
                .y0(d => y(d['lower']))
                .y1(d => y(d['upper']));


            this.svg.append("path")
                .datum(linePoints)
                .attr("d", areaGenerator)
                .attr("fill", "gray")
                .attr("opacity", 0.5);

  
            this.svg.append("path")
                .datum(linePoints)
                .attr("d", lineGenerator)
                .attr("stroke", "blue")
                .attr("stroke-width", 4)
                .attr("fill", "none");


            var thisRef = this;
            this.svg.selectAll("dot")
                .data(this.boxData)
                .enter().append("circle")
                .attr("cx", function (d) { return x(d.meta); })
                .attr("cy", function (d) { return y(d.feature); })
                .attr("r", 4)
                .style("fill", "#000000")
                .attr("opacity", 0.8)
                .on('mouseover', (event, d) => {
                    d3.select(event.currentTarget).transition()
                        .duration(100)
                        .attr("r", 6)
                        .style("fill", "red")
                    var coords = d3.pointer(event);
                    d3.select('.chart-tooltip')
                        .style("left", (coords[0] + 100) + "px")
                        .style("top", (coords[1] + 100) + "px")
                        .style("display", "block")
                        .style("visibility", "visible")
                        .text(d.record);
                })
                .on('mouseout', (event) => {
                    d3.select(event.currentTarget).transition()
                        .duration(200)
                        .attr("r", 4)
                        .style("fill", "black");
                    d3.select('.chart-tooltip')
                        .style("visibility", "hidden");;
                })
                .on('click', (d) => {
                    console.log(d.target.__data__.record)
                    thisRef.newItemEvent.emit(d.target.__data__.record);
                });


        }
    };


    prepareBoxplotData(data) {
        const categories = Array.from(new Set(data.map(d => d.meta)));
        return categories.map((category, index) => {
            const values = data.filter(d => d.meta === category)
                .map(d => +d.feature)  // Ensure conversion to number
                .sort(d3.ascending);
            const q1 = d3.quantile(values, 0.25);
            const median = d3.quantile(values, 0.5);
            const q3 = d3.quantile(values, 0.75);
            const whiskerLow = d3.min(values);
            const whiskerHigh = d3.max(values);
            return { key: index, category, q1, median, q3, whiskerLow, whiskerHigh };
        });
    }

    flatten(arr: any[][]) {
        let flatArray: any[] = [];
        for (let i = 0; i < arr.length; i++) {
            flatArray = flatArray.concat(arr[i]);
        }
        return flatArray;
    }

    findLabelByValue(data, searchValue) {
        for (let item of data) {
            if (item.value === searchValue) {
                return item.label;
            }
        }
        return null;
    }

    deeplyFilterByValueAndReturnLabel(dataArray, searchValue) {
        let labels = [];
        function searchItems(items) {
            items.forEach(item => {
              
                if (item.value === searchValue) {
                    labels.push(item.label);
                }
             
                if (item.items && Array.isArray(item.items)) {
                    searchItems(item.items);
                }
            });
        }

        searchItems(dataArray);
        return labels;
    }



}