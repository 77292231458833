<div class="p-grid" style='padding:50px 50px 0px 50px;'>
    <div class="p-col-12">
        <div class="card" style='font-size:14pt; padding:60px 100px'>
            <h1>About</h1>
            <div style="margin-top:30px">
                <p>
                    HumanIslets.com is a community resource in support of research in islet biology, transplantation,
                    and
                    diabetes. This tool brings together datasets collected through the processing, culture, quality
                    control,
                    and phenotyping of human research pancreas and islets processed by the
                    <a href="https://www.bcell.org/adi-isletcore.html" target="_blank">Alberta Diabetes Institute
                        IsletCore</a>
                    with analysis tools developed by the
                    <a href="http://www.xialab.ca/" target="_blank">Xia Lab</a> allowing data exploration and hypothesis
                    generation.
                </p>
                <p>
                    Development of this resource was made possible by a team grant funded by the Canadian Institutes of
                    Health Research, JDRF Canada, and Diabetes Canada (5-SRA-2021-1149-S-B/TG 179092). The following
                    investigators (alphabetical by last name) generously contributed data, development, or other input
                    to this resource:
                </p>
                <p style="margin-left:100px">
                    <a href="https://profils-profiles.science.gc.ca/en/profile/dr-ella-atlas" target="_blank">Ella
                        Atlas</a> (Health Canada/UOttawa),
                    <a href="https://carleton.ca/biology/people/jenny-bruin/" target="_blank">Jenny Bruin</a>
                    (Carleton),
                    <a href="https://www.msl.ubc.ca/people/dr-leonard-foster/" target="_blank">Leonard Foster</a> (UBC),
                    <a href="https://profiles.stanford.edu/anna-gloyn" target="_blank">Anna Gloyn</a> (Stanford),
                    <a href="https://cps.med.ubc.ca/faculty/johnson/" target="_blank">Jim Johnson</a> (UBC),
                    <a href="https://surgery.med.ubc.ca/people/flynn/" target="_blank">Francis Lynn</a> (UBC),
                    <a href="http://www.bcell.org/" target="_blank">Patrick MacDonald</a> (UAlberta),
                    <a href="https://www.uottawa.ca/faculty-medicine/dr-erin-mulvihill" target="_blank">Erin
                        Mulvihill</a> (UOttawa),
                    <a href="https://apps.ualberta.ca/directory/person/apepper" target="_blank">Andrew Pepper</a>
                    (UAlberta),
                    <a href="https://www.bcchr.ca/bverchere" target="_blank">Bruce Verchere</a> (UBC),
                    <a href="http://www.xialab.ca/" target="_blank">Jeff Xia</a> (University of McGill).
                </p>
            </div>

            <h1 style="margin-top: 100px;">Further growth and development</h1>
            <div style="margin-top:30px">
                <p>
                    We will be adding additional donors, datasets, and analysis tools over time. Please check the list
                    of <a routerLink="/updates">updates</a> for the latest information. Current data availability is
                    shown below
                    (rows are datatype, columns are donor ID, and donors are ordered in chronological order):
                </p>
                <div style="margin-top:50px">
                    <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="80%">
                </div>

            </div>

            <h1 style="margin-top: 100px;">Feedback</h1>
            <div style="margin-top:30px">
                <p>
                    We welcome feedback, as we are continuously working to increase the utility of this tool for the
                    community. Feedback on the web tool, interface and analysis methods can be sent to Dr. Jeff Xia
                    (jeff.xia@mcgill.ca). Feedback regarding human tissue and data collection can be sent to Dr. Patrick
                    MacDonald (pmacdonald@ualberta.ca).
                </p>
            </div>

            <h1 style="margin-top: 100px;">Publications</h1>
            <div style="margin-top:30px">
                <p-scrollPanel [style]="{ width: '100%', height: '400px', 'font-size': '14pt' }">
                    <p>
                        Ewald et al. (2024) HumanIslets: An open resource for human research islet data access and analysis. 
                        Cell Metabolism(Accepted). 
                        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11212983/" target="_blank">
                            doi:10.1101/2024.06.19.599613
                        </a>.
                    </p>
                    <p>
                        Lyon et al. (2024) Human research islet culture outcomes at the Alberta Diabetes Institute
                        IsletCore. bioRxiv, 6:2024.06.18.599388.
                        <a href="https://www.biorxiv.org/content/10.1101/2024.06.18.599388v1" target="_blank">
                            doi:10.1101/2024.06.18.599388</a>.
                    </p>
                    <p>
                        Kolic et al. (2024) Proteomic predictors of individualized nutrient-specific insulin secretion
                        in health and disease. medRxiv, 5:2023.05.24.23290298.
                        <a href="https://pubmed.ncbi.nlm.nih.gov/38496562/" target="_blank">doi:
                            10.1101/2023.05.24.23290298</a>.
                    </p>
                    <p>
                        Dai et al. (2022) Heterogenous impairment of α cell function in type 2 diabetes is linked to
                        cell maturation state. Cell Metabolism, 34(2):256-268.e5.
                        <a href="https://pubmed.ncbi.nlm.nih.gov/35108513/" target="_blank">doi:
                            10.1016/j.cmet.2021.12.021</a>.
                    </p>
                    <p>
                        Camunas-Soler et al. (2020) Patch-seq links single-cell transcriptomes to human islet
                        dysfunction in diabetes. Cell Metabolism, 31(5):1017-1031.e4.
                        <a href="https://pubmed.ncbi.nlm.nih.gov/32302527/" target="_blank">doi:
                            10.1016/j.cmet.2020.04.005</a>.
                    </p>
                    <p>
                        Lyon et al. (2016) Research-Focused Isolation of Human Islets From Donors With and Without
                        Diabetes at the Alberta Diabetes Institute IsletCore. Endocrinology, 157(2):560-9.
                        <a href="https://pubmed.ncbi.nlm.nih.gov/26653569/" target="_blank">doi:
                            10.1210/en.2015-1562</a>.
                    </p>
                </p-scrollPanel>
            </div>

        </div>
    </div>
</div>