<style>
  .custom-download {
    font-size: 16px; 
    padding: 5px 18px; 
}

.custom-download .pi-download {  
    transform: scale(1.2);  
    margin-right: 8px; 
}

.custom-icon-size .pi {
    font-size: 1.5em;   
    transform: scale(1.2);  
}

</style>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card p-d-flex p-ai-center p-jc-between">
            <h1 style='padding-left: 50px; margin:0px'>Data Download {{testString}}</h1>
            <button pButton type="button" label="Reset Dataset" class="p-button-warning" (click)="resetData()"></button>
        </div>
    </div>
</div>
<div style="margin-left: 30px">
    <p-messages></p-messages>
</div>
<div class="p-grid">
    <div class="p-col-6">
        <div class="card" [style]="{'height': '750px', 'padding': '50px'}">
            <h4>Select Donors</h4>
            <p>Click "Submit" without specifying any filters to download data for all donors.</p>
            <div style="margin-top: 15px">
                <app-filter (emitFilterSuccess)="getFilterSuccess($event)"></app-filter>
            </div>
        </div>
    </div>

    <div class="p-col-6">
        <div class="card" [style]="{'height': '750px', 'padding': '40px'}">
            <h4>Download Data</h4>
            <div class="p-d-flex p-ai-center p-grid">
                <div class="p-col-12">
                    <div style="margin-top:20px;" class="p-grid p-ai-center p-jc-left">
                        <div class="p-col-3">
                            <label style="font-weight: bold;">Select datasets:</label>
                        </div>
                        <div class="p-col-9">
                            <p-multiSelect [options]="dataType" [group]="true" [(ngModel)]="selectedDataType"
                                scrollHeight="250px" display="chip" defaultLabel="Select data types to download"
                                [style]="{'width':'400px'}" [showHeader]="false" [maxSelectedLabels]="100">
                                <ng-template let-group pTemplate="group">
                                    <div class="flex align-items-center">
                                        {{group.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        <div class="p-col-3" style="margin-top:30px">
                            <label style="font-weight: bold;">Data format:</label>
                        </div>
                        <div class="p-col-9" style="margin-top:30px">
                            <p-dropdown [options]="fileType" [(ngModel)]="selectedFileType" optionLabel="label"
                                optionValue="value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-ai-center" style="margin-top:30px">
                Note: due to its large size, download of single-cell patchSeq data is handled differently. Click &nbsp;
                <a (click)="displayPatchSeq=true" style="cursor: pointer; text-decoration: underline;">here</a>&nbsp;
                to access these data.
            </div>
            <div class="p-d-flex p-ai-center" style="margin-top:30px">
                <div>
                    <button pButton type="button" label="Download" icon="pi pi-download" iconPos="right"
                        [disabled]="!step2" (click)="downloadTables()" [loading]="dwnld_loading"></button>
                </div>
                <div style="padding-left:20px">
                    <button pButton type="button" label="Summary" [disabled]="!step2" icon="pi pi-chart-bar"
                        iconPos="right" (click)="dataSummary()"></button>
                </div>
            </div>
            <div style="margin-top: 30px" class="p-d-flex p-ai-center p-grid">
                <div class="p-col-12" *ngIf="dataStats">
                    {{printSummary}}
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Dataset Summary" [(visible)]="displayDataSummary" [style]="{width: '575px', height: '875px'}">
    <div class="p-d-flex p-jc-center p-ai-center" style="width:100%; height:100%">
        <div class="wrapper" *ngIf="loadingImage == 'yes'">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div style="padding-top:20px">
            <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="400px">
        </div>
    </div>
    <ng-template pTemplate="footer">
           <button pButton type="button" label="SVG" (click)="dwlFeature('svg')" 
                class="custom-download"></button>
        <button pButton type="button" label="PNG" (click)="dwlFeature('png')" 
                class="custom-download"></button>
         <button pButton type="button" label="PDF" (click)="dwlFeature('pdf')" 
                class="custom-download"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDataSummary=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Download patchSeq data" [(visible)]="displayPatchSeq" [style]="{width: '600px', height: '500px'}">
    <p>
        The patchSeq data is composed of paired single-cell RNAseq data and single-cell electrophysiology outcomes. The electrophysiology 
        outcomes for all cells can be found here:
    </p>
    <ul>
        <li>
            <a (click)="downloadPatchSeq('ephys_cell.csv')" style="cursor: pointer; text-decoration: underline;">ephys_cell.csv</a>
        </li>
    </ul>
    <p>
        Normalized single-cell RNAseq data are separated by cell type and glucose concentration. A description of the data processing methods used to 
        produce these tables is available 
        <a href="https://xia-lab.github.io/HumanIsletsDoc/overview-of-omics-data-types.html#gene-expression-single-cell-rna-seq" target="_blank">here</a>. 
        Click the links below to download HDF5 files containing the data:  
    </p>
    <ul>
        <li style="margin-bottom: 10px;">
            <a (click)="downloadPatchSeq('sc_Alpha_1.h5')" style="cursor: pointer; text-decoration: underline;">Alpha cells, 1mM glucose</a>
        </li>
        <li style="margin-bottom: 10px;">
            <a (click)="downloadPatchSeq('sc_Alpha_5.h5')" style="cursor: pointer; text-decoration: underline;">Alpha cells, 5mM glucose</a>
        </li>
        <li style="margin-bottom: 10px;">
            <a (click)="downloadPatchSeq('sc_Alpha_10.h5')" style="cursor: pointer; text-decoration: underline;">Alpha cells, 10mM glucose</a>
        </li>
        <li style="margin-bottom: 10px;">
            <a (click)="downloadPatchSeq('sc_Beta_1.h5')" style="cursor: pointer; text-decoration: underline;">Beta cells, 1mM glucose</a>
        </li>
        <li style="margin-bottom: 10px;">
            <a (click)="downloadPatchSeq('sc_Beta_5.h5')" style="cursor: pointer; text-decoration: underline;">Beta cells, 5mM glucose</a>
        </li>
        <li style="margin-bottom: 10px;">
            <a (click)="downloadPatchSeq('sc_Beta_10.h5')" style="cursor: pointer; text-decoration: underline;">Beta cells, 10mM glucose</a>
        </li>
    </ul>
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displayPatchSeq=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>