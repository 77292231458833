<style>
    li {
        margin: 0 0 5px 0;
    }

    .screenshot {
        border: 0.5px solid grey;
        margin-top: 15px;
    }

    .center-logo {
        height: 3vw; /* Initial relative size based on viewport width */
        max-height: 30px; /* Maximum size to prevent overly large images on bigger screens */
        min-height: 20px; /* Minimum size to ensure visibility on smaller screens */
    }

    @media (max-width: 768px) { /* Adjust this breakpoint as needed */
        .center-logo {
            height: 5vw; /* Increase size relative to viewport width for smaller screens */
            max-height: 25px; /* Adjust max size for smaller screens */
        }
    }
</style>
<div class="p-grid" style="height: calc(100vh - 148px) !important;">
    <div class="p-col-9">
        <div class="p-grid" style="height: calc(100vh - 148px) !important;">
            <div class="p-col-12 p-d-flex p-jc-center p-ai-top" style="height: calc((100vh - 148px)*0.10) !important;">
                <div style="background-color: #FFF; color: #000; width: 90%; height: calc((100vh - 148px)*0.10*0.6); display: flex;
                     justify-content: center; align-items: center; font-size: 2.25vmin; border-radius: 10px; border: 1px solid grey;
                     margin-top:20px">
                    547 donors&nbsp;&nbsp;&nbsp;---&nbsp;&nbsp;&nbsp;5 omics
                    platforms&nbsp;&nbsp;&nbsp;---&nbsp;&nbsp;&nbsp;74 donor, technical, and functional metadata
                </div>
            </div>
            <div class="p-col-5 p-d-flex p-jc-center p-ai-center" style="height: calc((100vh - 148px)*0.60); display: flex; align-items: center; justify-content: center;">
                <div class="circle" style="margin-top:10%">
                    <div class="small-circle" routerLink="/donor">Donor View</div>
                    <div class="small-circle" routerLink="/omics">Omics View</div>
                    <div class="small-circle" routerLink="/feature">Feature View</div>
                </div>
            </div>
            <div class="p-col-7" style="height: calc((100vh - 148px)*0.60) !important; display: flex; align-items: center; justify-content: center;">
                <div class="p-d-flex p-flex-column" style="width:100%; padding-right:15%;">
                    <span style="font-size:4vmin; font-weight: bold; text-align: center;">
                        Understanding Human Islet Variation in Health and Diabetes
                    </span>
                    <span style="margin-top:10px; font-size: 1.75vmin; text-align: center; padding:0 10%">
                        Explore islet phenotyping data by donor ID, omics type, or feature with our
                        visual analytics tools
                    </span>
                    <div class="p-d-flex p-jc-center" style='padding-top:5%'>
                        <img class="center center-logo" src="assets/layout/images/adi_logo.jpeg" height="30"
                             style="padding-right:25px">
                        <img class="center center-logo" src="assets/layout/images/cihr.jpg" height="30">
                        <img class="center center-logo" src="assets/layout/images/Diabetes_Canada.png" height="30"
                             style="padding:0px 25px">
                        <img class="center center-logo" src="assets/layout/images/JDRF.jpg" height="30">
                    </div>
                    <div class="p-d-flex p-jc-center" style='padding-top:8%'>
                        <button pButton type="button" label="Download Data"
                                class="p-button-primary" routerLink="/download"
                                icon="pi pi-download"></button>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-jc-center p-ai-center" style="height: calc((100vh - 148px)*0.3) !important;">
                <div style="width: 90%; justify-content: center; align-items: center; margin: 0 5%;
                     border: 1px solid grey; border-radius:20px; height: calc((100vh - 148px)*0.3 - 20px) !important; overflow: auto;">
                    <h4 style="margin:30px 40px 0px 40px">Updates</h4>
                    <p-scrollPanel [style]="{ width: '100%', height: '100%'}">
                        <div style="padding:0px 30px;  overflow: scroll;">
                            <ul>
                                <li>
                                       <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11212983/" target="_blank">Our paper</a>  
                                 has been accepted at Cell Metabolism! (09/20/2024)
                                </li> 
                                <li>
                                    Added the link for HumanIslets.com Webinar(08/26/2024)
                                </li>
                                <li>
                                    Added tutorial for a website walkthrough(06/20/2024)
                                </li> 
                                <li>
                                    Added dynamic perifusion data for ~20 more donors (05/19/2024)
                                </li>                                
                                <li>
                                    Added an 'About' page (05/12/2024)
                                </li>
                                <li>
                                    Updated the 'Terms of Service' (05/12/2024)
                                </li>
                            </ul>
                        </div>
                    </p-scrollPanel>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-3" style="height: calc(100vh - 148px) !important;">
        <div style="width: 90%; height:calc(100vh - 188px); justify-content: center; align-items: center;
             border: 1px solid grey; border-radius:20px; padding:30px; margin-top:20px">
            <div style="height:80px; font-size:1.9vmin; text-align: center;">
                See the <a href="https://xia-lab.github.io/HumanIsletsDoc/using-humanislets.html#using-humanislets.com" target="_blank">documentation</a>, <a href="assets/file/HumanIslets_interface_walkthrough.pdf" target="_blank">tutorial</a> or 
                <a href="https://www.youtube.com/watch?v=-XdD84gfZdA" target="_blank">webinar</a> on how  to use the HumanIslets tool
            </div>
            <p-scrollPanel [style]="{ width: '100%', height: 'calc(100% - 80px)'}">
                <img class="center screenshot" src="assets/layout/images/dea.png" width="100%">
                <img class="center screenshot" src="assets/layout/images/pw.png" width="100%">
                <img class="center screenshot" src="assets/layout/images/donor.png" width="100%">
                <img class="center screenshot" src="assets/layout/images/download.png" width="100%">
            </p-scrollPanel>
        </div>
    </div>
</div>